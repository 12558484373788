
export default [
  {
    id: "1-1",
    diff: 3,
    question: "個人のライフプランニングにおいて、キャッシュフロー表に記載する金額は、物価変動等が予測されるものについては、通常、その変動等を加味した将来価値で計上する。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "1-2",
    diff: 3,
    question: "給与収入が 700 万円、所得税・住民税が 60 万円、社会保険料が 100 万円、生命保険料が 10 万円のとき、可処分所得は 530 万円である。（21 年９月）",
    lecture: "可処分所得＝年収－ ( 所得税＋住民税＋社会保険料)です。本問は 700 万円 －（60 万円 ＋ 100 万円）＝ 540 万円です。",
    answer: 0,
    categoryId: 1,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "1-3",
    diff: 3,
    question: "元金を一定の利率で複利運用しながら、毎年一定金額を一定の期間にわたり取り崩していくときの毎年の取崩し金額を計算する場合、元金に乗じる係数は、資本回収係数である。（19 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 3,
    type: 1,
    lessonNo: 2
  },
  {
    id: "1-4",
    diff: 2,
    question: "税理士資格を有しないファイナンシャル・プランナーが、顧客のために反復継続して確定申告書を作成しても、その行為が無償であれば税理士法に抵触しない。（20 年９月）",
    lecture: "税理士法では、税理士・税理士法人でない者が、他人の求めに応じ税務の代行、税務書類の作成、個別具体的な計算を含む税務相談などを行うことについて、有償・無償を問わず禁止しています。",
    answer: 0,
    categoryId: 1,
    num: 4,
    type: 1,
    lessonNo: 4
  },
  {
    id: "1-5",
    diff: 2,
    question: "ファイナンシャル・プランナーは、顧客の依頼を受けたとしても、公正証書遺言の作成時に証人となることはできない。（20 年１月）",
    lecture: "特別の資格がなくても証人にはなれますが、推定相続人、受遺者（推定相続人ではないが遺贈を受ける者）、その配偶者、直系血族などは、公正証書遺言の証人にはなれません。",
    answer: 0,
    categoryId: 1,
    num: 5,
    type: 1,
    lessonNo: 4
  },
  {
    id: "1-6",
    diff: 3,
    question: "日本政策金融公庫の教育一般貸付（国の教育ローン）において、融資の対象となる学校は、中学校、高等学校、大学、大学院等の小学校卒業以上の者を対象とする教育施設である。（22 年１月）",
    lecture: "教育一般貸付の融資対象となる学校は、中学校卒業以上の者を対象とする教育施設ですので、中学校は対象外です。",
    answer: 0,
    categoryId: 1,
    num: 6,
    type: 1,
    lessonNo: 5
  },
  {
    id: "1-7",
    diff: 2,
    question: "日本政策金融公庫の教育一般貸付（国の教育ローン）は、日本学生支援機構の奨学金制度と重複して利用することができない。（21 年９月）",
    lecture: "要件を満たせば、教育一般貸付と日本学生支援機構の奨学金制度を重複して利用することもできます。",
    answer: 0,
    categoryId: 1,
    num: 7,
    type: 1,
    lessonNo: 5
  },
  {
    id: "1-8",
    diff: 3,
    question: "日本政策金融公庫の教育一般貸付（国の教育ローン）の金利は固定金利で、返済期間は、最長 18 年である。（21 年３月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 8,
    type: 1,
    lessonNo: 5
  },
  {
    id: "1-9",
    diff: 2,
    question: "日本学生支援機構が取り扱う奨学金には、返済不要の第一種奨学金と利子付（在学中は無利息）の第二種奨学金がある。",
    lecture: "貸与型のため返済が必要であり、無利子の第一種奨学金と利子付（在学中は無利子）の第二種奨学金と、返済不要の給付型があります。",
    answer: 0,
    categoryId: 1,
    num: 9,
    type: 1,
    lessonNo: 6
  },
  {
    id: "1-10",
    diff: 3,
    question: "住宅金融支援機構と民間金融機関が提携した住宅ローンであるフラット35の融資金利は固定金利であり、その利率は取扱金融機関がそれぞれ独自に決定している。（20 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 10,
    type: 1,
    lessonNo: 6
  },
  {
    id: "1-11",
    diff: 1,
    question: "住宅金融支援機構と民間金融機関が提携した住宅ローンであるフラット35（買取型）の融資額は、土地取得費を含めた住宅建設費用または住宅購入価額以内で、最高 1 億円である。（ 23 年９月改）",
    lecture: "融資額は、最高 8,000万円です。",
    answer: 0,
    categoryId: 1,
    num: 11,
    type: 1,
    lessonNo: 6
  },
  {
    id: "1-12",
    diff: 3,
    question: "住宅ローンの返済方法のうち、元利均等返済は、毎回の返済額が一定で、返済期間の経過とともに毎回の元金の返済額が減少する返済方法である。（21 年５月）",
    lecture: "元利均等返済は、毎回の返済額が一定で、返済期間の経過とともに毎回の元金の返済額が増加する返済方法です。",
    answer: 0,
    categoryId: 1,
    num: 12,
    type: 1,
    lessonNo: 6
  },
  {
    id: "1-13",
    diff: 3,
    question: "住宅ローンの総返済額は、借入額、金利、借入期間等の条件が同一であれば、通常、元金均等返済よりも元利均等返済のほうが多くなる。（21 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 13,
    type: 1,
    lessonNo: 6
  },
  {
    id: "1-14",
    diff: 2,
    question: "貸金業法の総量規制により、個人が貸金業者による個人向け貸付を利用する場合の借入合計額は、原則として、年収の 4 分の 1 以内でなければならない。（19 年９月）",
    lecture: "個人が貸金業者（クレジットカード会社、消費者金融会社、信販会社）を利用した個人向け貸付を利用する場合、総量規制により借入金の合計は年収の３分の１以内でなければなりません。",
    answer: 0,
    categoryId: 1,
    num: 14,
    type: 1,
    lessonNo: 6
  },
  {
    id: "1-15",
    diff: 3,
    question: "健康保険の被保険者が同一月内に同一の医療機関等で支払った医療費の一部負担金等の額が、その者に係る自己負担限度額を超えた場合、その支払った一部負担金等の全額が、高額療養費として支給される。（21 年１月）",
    lecture: "一部負担金等が自己負担限度額を超えたときに、自己負担限度額を超えた分が高額療養費として支給されます。",
    answer: 0,
    categoryId: 1,
    num: 15,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-16",
    diff: 3,
    question: "全国健康保険協会管掌健康保険の被保険者に支給される傷病手当金の額は、１日につき、原則として、支給開始日の属する月以前 12 カ月間の各月の標準報酬月額の平均額を 30 で除した金額に、４分の３を乗じた額である。（18 年９月）",
    lecture: "傷病手当金の額は、１日につき、支給開始日の属する月以前 12 カ月間の各月の標準報酬月額の平均額を 30 で除した金額に、３分の２を乗じた額です。",
    answer: 0,
    categoryId: 1,
    num: 16,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-17",
    diff: 2,
    question: "全国健康保険協会管掌健康保険の被保険者に支給される傷病手当金の支給期間は、同一の疾病または負傷およびこれにより発した疾病に関して、その支給開始日から最長 1 年 6 カ月である。（21 年３月）",
    lecture: "被保険者が病気やケガで連続して 3 日以上休業して、給料が支払われない場合、休業 4 日目から支給開始日より通算で 1 年 6 カ月を限度に傷病手当金が支給されます。最長ではなく通算がポイントです。",
    answer: 0,
    categoryId: 1,
    num: 17,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-18",
    diff: 2,
    question: "全国健康保険協会管掌健康保険の被保険者が、産科医療補償制度に加入する病院で出産した場合の出産育児一時金の額は、1 児につき 50 万円である。",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 18,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-19",
    diff: 3,
    question: "健康保険の任意継続被保険者となるためには、健康保険の被保険者資格を喪失した日の前日まで継続して 2 カ月以上被保険者であった者が、原則として、資格喪失の日から 14 日以内に任意継続被保険者の資格取得手続きを行う必要がある。（19 年５月）",
    lecture: "原則として 20 日以内です。なお、国民健康保険の被保険者となるためには14 日以内に手続きを行う必要があります。",
    answer: 0,
    categoryId: 1,
    num: 19,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-20",
    diff: 3,
    question: "健康保険の被保険者（障害の状態にない）は、原則として、70 歳になると健康保険の被保険者資格を喪失し、後期高齢者医療制度の被保険者となる。（21 年１月）",
    lecture: "75 歳になると後期高齢者医療制度の被保険者になります。",
    answer: 0,
    categoryId: 1,
    num: 20,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-21",
    diff: 2,
    question: "公的介護保険の第 2 号被保険者は、要介護状態または要支援状態となった原因を問わず、保険給付を受けることができる。（21 年５月）",
    lecture: "介護保険の第 2 号被保険者（40 歳以上 65 歳未満）は、加齢に伴う特定疾病が原因で要介護（要支援）認定を受けたときに保険給付を受けることができます。",
    answer: 0,
    categoryId: 1,
    num: 21,
    type: 1,
    lessonNo: 8
  },
  {
    id: "1-22",
    diff: 2,
    question: "アルバイトやパートタイマーが、労働者災害補償保険の適用を受けるためには、１週間の所定労働時間が 20 時間を超えていなければならない。（ 23 年９月）",
    lecture: "所定労働時間に関係なく、アルバイトやパートタイマーを含むすべての労働者が適用を受けます。",
    answer: 0,
    categoryId: 1,
    num: 22,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-23",
    diff: 3,
    question: "労働者災害補償保険の保険料は、その全額を事業主が負担する。（21 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 23,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-24",
    diff: 3,
    question: "雇用保険の基本手当を受給するためには、倒産、解雇および雇止めなどの場合を除き、原則として、離職の日以前 1 年間に通算 6 カ月以上被保険者期間があることなどの要件を満たす必要がある。（21 年１月）",
    lecture: "離職日以前 1 年間に 6 カ月ではなく、2 年間に 12 カ月以上です。なお、連続している必要はなく「通算」もポイントです。",
    answer: 0,
    categoryId: 1,
    num: 24,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-25",
    diff: 3,
    question: "20 年以上勤務した会社を 60 歳到達月の末日で定年退職し、雇用保険の基本手当の受給資格者となった者が受給することができる基本手当の日数は、最大 200 日である。（22 年１月）",
    lecture: "最大 150 日です。",
    answer: 0,
    categoryId: 1,
    num: 25,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-26",
    diff: 2,
    question: "雇用保険の教育訓練給付金のうち、一般教育訓練に係る教育訓練給付金の額は、教育訓練施設に支払った教育訓練経費の 20％相当額（10 万円を限度）である。（22 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 26,
    type: 1,
    lessonNo: 9
  },
  {
    id: "1-27",
    diff: 3,
    question: "国民年金の第１号被保険者によって生計を維持している配偶者で 20 歳以上60 歳未満の者は、国民年金の第３号被保険者となる。（21 年５月）",
    lecture: "国民年金の第３号被保険者は、第２号被保険者によって生計を維持している配偶者で 20 歳以上 60 歳未満の者です。",
    answer: 0,
    categoryId: 1,
    num: 27,
    type: 1,
    lessonNo: 10
  },
  {
    id: "1-28",
    diff: 2,
    question: "国民年金の保険料免除期間に係る保険料のうち、追納することができる保険料は、追納に係る厚生労働大臣の承認を受けた日の属する月前 10 年以内の期間に係るものに限られる。（21 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 28,
    type: 1,
    lessonNo: 11
  },
  {
    id: "1-29",
    diff: 3,
    question: "国内に住所を有する 60 歳以上 75 歳未満の者は、厚生年金保険の被保険者である者を除き、国民年金の任意加入被保険者となることができる。（19 年９月）",
    lecture: "設問の場合、年齢の要件は 60 歳以上 65 歳未満です。",
    answer: 0,
    categoryId: 1,
    num: 29,
    type: 1,
    lessonNo: 11
  },
  {
    id: "1-30",
    diff: 1,
    question: "2009 年 4 月以後の国民年金の保険料全額免除期間（学生納付特例制度等の適用を受けた期間を除く）は、その 3 分の 1 に相当する月数が老齢基礎年金の年金額に反映される。（21 年９月）",
    lecture: "2 分の 1 に相当する月数が反映されます。",
    answer: 0,
    categoryId: 1,
    num: 30,
    type: 1,
    lessonNo: 12
  },
  {
    id: "1-31",
    diff: 2,
    question: "国民年金の被保険者が学生納付特例制度の適用を受けた期間は、その期間に係る保険料を追納しない場合、老齢基礎年金の受給資格期間に算入されず、老齢基礎年金の年金額にも反映されない。（21 年５月）",
    lecture: "学生納付特例によって猶予された期間は、老齢基礎年金の受給資格期間に算入されますが、老齢基礎年金の年金額には反映されません。",
    answer: 0,
    categoryId: 1,
    num: 31,
    type: 1,
    lessonNo: 12
  },
  {
    id: "1-32",
    diff: 3,
    question: "65 歳到達時に老齢基礎年金の受給資格期間を満たしている者が、68 歳到達日に老齢基礎年金の繰下げ受給の申し出をした場合の老齢基礎年金の増額率は、18％となる。（18 年５月）",
    lecture: "老齢基礎年金の繰下げ受給は１カ月あたり 0.7％増額なので、増額率は\u30000.7％× 12 カ月×３年＝ 25.2％\u3000となります。",
    answer: 0,
    categoryId: 1,
    num: 32,
    type: 1,
    lessonNo: 12
  },
  {
    id: "1-33",
    diff: 3,
    question: "国民年金の第１号被保険者が、国民年金の定額保険料に加えて月額 200 円の付加保険料を納付し、65 歳から老齢基礎年金を受け取る場合、400 円に付加保険料納付済期間の月数を乗じて得た額が付加年金として支給される。（19 年１月）",
    lecture: "支払う付加保険料は月額 400 円、受け取る付加年金は、200 円×付加保険料納付済月数です。",
    answer: 0,
    categoryId: 1,
    num: 33,
    type: 1,
    lessonNo: 12
  },
  {
    id: "1-34",
    diff: 1,
    question: "国民年金の付加保険料納付済期間を有する者が、老齢基礎年金の繰下げ支給の申出をした場合、付加年金は、老齢基礎年金と同様の増額率によって増額される。（22 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 34,
    type: 1,
    lessonNo: 12
  },
  {
    id: "1-35",
    diff: 2,
    question: "老齢厚生年金に加給年金額が加算されるためには、老齢厚生年金の受給権者本人が有する厚生年金保険の被保険者期間が原則として 25 年以上なければならない。（19 年１月）",
    lecture: null,
    answer: 0,
    categoryId: 1,
    num: 35,
    type: 1,
    lessonNo: 13
  },
  {
    id: "1-36",
    diff: 2,
    question: "子のいない障害等級 1 級に該当する者に支給される障害基礎年金の額は、子のいない障害等級 2 級に該当する者に支給される障害基礎年金の額の1.25 倍に相当する額である。（21 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 36,
    type: 1,
    lessonNo: 14
  },
  {
    id: "1-37",
    diff: 2,
    question: "遺族基礎年金を受給することができる遺族は、国民年金の被保険者等の死亡の当時、その者によって生計を維持され、かつ、所定の要件を満たす「子のある妻」または「子」である。（22 年５月）",
    lecture: "「子のある配偶者」または「子」です。",
    answer: 0,
    categoryId: 1,
    num: 37,
    type: 1,
    lessonNo: 15
  },
  {
    id: "1-38",
    diff: 2,
    question: "遺族厚生年金の額（中高齢寡婦加算額および経過的寡婦加算額を除く）は、原則として、死亡した者の厚生年金保険の被保険者記録を基礎として計算した老齢厚生年金の報酬比例部分の額の 3 分の 2 に相当する額である。（22 年１月）",
    lecture: "4 分の 3 に相当する額です。",
    answer: 0,
    categoryId: 1,
    num: 38,
    type: 1,
    lessonNo: 15
  },
  {
    id: "1-39",
    diff: 3,
    question: "確定拠出年金の個人型年金の加入者が国民年金の第 1 号被保険者である場合、原則として、掛金の拠出限度額は年額 816,000 円である。（22 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 1,
    num: 39,
    type: 1,
    lessonNo: 16
  },
  {
    id: "1-40",
    diff: 3,
    question: "国民年金基金の掛金の額は、加入員の選択した給付の型や加入口数によって決まり、加入時の年齢や性別によって異なることはない。（22 年９月）",
    lecture: "掛金の額は、加入時の年齢、性別、選択した給付の型、加入口数などによって決まります。",
    answer: 0,
    categoryId: 1,
    num: 40,
    type: 1,
    lessonNo: 16
  },
  {
    id: "2-1",
    diff: 1,
    question: "国内で事業を行う少額短期保険業者と締結した保険契約は、生命保険契約者保護機構および損害保険契約者保護機構による補償の対象とならない。（21 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 1,
    type: 1,
    lessonNo: 2
  },
  {
    id: "2-2",
    diff: 1,
    question: "国内で事業を行う生命保険会社が破綻した場合、生命保険契約者保護機構による補償の対象となる保険契約は、高予定利率契約を除き、死亡保険金額の 90％まで補償される。（22 年９月・改）",
    lecture: "高予定利率契約を除いて、責任準備金等の 90％まで補償されます。",
    answer: 0,
    categoryId: 2,
    num: 2,
    type: 1,
    lessonNo: 2
  },
  {
    id: "2-3",
    diff: 1,
    question: "国内銀行の窓口において加入した個人年金保険は、生命保険契約者保護機構による保護の対象となるのではなく、預金保険機構による保護の対象となる。（19 年１月・改）",
    lecture: "銀行が販売する生命保険や個人年金保険も、生命保険契約者保護機構の対象です。",
    answer: 0,
    categoryId: 2,
    num: 3,
    type: 1,
    lessonNo: 2
  },
  {
    id: "2-4",
    diff: 2,
    question: "生命保険会社のソルベンシー・マージン比率が 100％を超えていれば、通常の予測を超えるリスクに対する保険金等の支払余力が十分にあるとされ、金融庁による早期是正措置の対象とならない。（18 年１月）",
    lecture: "保険会社の健全性を計る指標であるソルベンシー・マージン比率は、200%を下回ると金融庁による早期是正措置の対象になります。",
    answer: 0,
    categoryId: 2,
    num: 4,
    type: 1,
    lessonNo: 2
  },
  {
    id: "2-5",
    diff: 1,
    question: "生命保険契約を申し込んだ者は、保険業法上、原則として、契約の申込日から８日以内であれば、口頭により申込みの撤回等をすることができる。（20 年１月）",
    lecture: "クーリング・オフの申込み撤回等の手続きは口頭ではなく、書面でする必要があります。",
    answer: 0,
    categoryId: 2,
    num: 5,
    type: 1,
    lessonNo: 2
  },
  {
    id: "2-6",
    diff: 1,
    question: "保険法の規定によれば、保険契約者や被保険者に告知義務違反があった場合、保険者は原則として保険契約を解除することができるが、この解除権は、保険者が解除の原因があることを知った時から１カ月間行使しないとき、または契約締結の時から５年を経過したときは消滅する。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 6,
    type: 1,
    lessonNo: 3
  },
  {
    id: "2-7",
    diff: 3,
    question: "生命保険の保険料のうち、保険会社が保険契約を維持・管理するための費用に充当される付加保険料は、予定死亡率および予定利率にもとづいて計算される。（19 年５月）",
    lecture: "付加保険料は予定事業費率にもとづいて計算され、純保険料は予定死亡率および予定利率にもとづいて計算されます。",
    answer: 0,
    categoryId: 2,
    num: 7,
    type: 1,
    lessonNo: 4
  },
  {
    id: "2-8",
    diff: 2,
    question: "逓増定期保険では、保険期間の経過にともない保険料が所定の割合で増加するが、保険金額は保険期間を通じて一定である。（21 年９月・改）",
    lecture: "逓増定期保険は、保険期間の経過にともない保険金額は増加しますが、保険料は保険期間を通じて一定です。",
    answer: 0,
    categoryId: 2,
    num: 8,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-9",
    diff: 2,
    question: "収入保障保険の死亡保険金を一時金で受け取る場合の受取額は、一般に、年金形式で受け取る場合の受取総額よりも少なくなる。（23年5月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 9,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-10",
    diff: 1,
    question: "定期保険特約付終身保険（更新型）は、定期保険特約の更新の都度、告知が必要であり、健康状態によっては定期保険特約を更新できない。（18 年５月）",
    lecture: "定期保険特約（更新型）は、告知不要で自動更新されます。",
    answer: 0,
    categoryId: 2,
    num: 10,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-11",
    diff: 1,
    question: "定期保険特約付終身保険では、定期保険特約を同額の保険金額で自動更新すると、更新後の保険料は、通常、更新前よりも安くなる。（18 年１月）",
    lecture: "定期保険特約の更新において、更新後の保険料は、その時点の年齢等で再計算されるため、同額の保険金額であれば通常は更新前よりも高くなります。",
    answer: 0,
    categoryId: 2,
    num: 11,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-12",
    diff: 2,
    question: "学資（こども）保険は、保険期間中に契約者が死亡した場合、死亡時点における解約返戻金相当額が支払われて保険契約が消滅する。（19 年９月）",
    lecture: "学資保険の契約者である親等が死亡した場合、それ以降の保険料の支払いは免除されますが、保険契約は継続し、満期保険金等は契約どおり支払われます。",
    answer: 0,
    categoryId: 2,
    num: 12,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-13",
    diff: 1,
    question: "個人年金保険において、確定年金は、年金支払期間中に被保険者が生存している場合に限り、契約で定めた一定期間、年金が支払われる。（21 年５月）",
    lecture: "契約で定めた期間のうち、被保険者が生存している場合に支払われるのは有期年金です。確定年金では、被保険者が年金支払期間中に死亡した場合には、遺族が残り期間の年金を受け取れます。",
    answer: 0,
    categoryId: 2,
    num: 13,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-14",
    diff: 1,
    question: "変額個人年金保険は、特別勘定の運用実績によって、将来受け取る年金額や死亡給付金額は変動するが、解約返戻金額は変動しない。（23年9月）",
    lecture: "変額個人年金保険は、一般に年金額や解約返戻金は変動しますが、死亡給付金額は最低保証があります。",
    answer: 0,
    categoryId: 2,
    num: 14,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-15",
    diff: 2,
    question: "がん保険では、一般に、責任開始日前に 180 日程度の免責期間が設けられており、その期間中にがんと診断されたとしてもがん診断給付金は支払われない。（20 年１月）",
    lecture: "がん保険は、責任開始日前に 90 日程度の免責期間が設けられています。",
    answer: 0,
    categoryId: 2,
    num: 15,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-16",
    diff: 2,
    question: "リビング・ニーズ特約は、被保険者の余命が 3 カ月以内と判断された場合に、所定の範囲内で死亡保険金の一部または全部を生前に受け取ることができる特約である。（21 年９月）",
    lecture: "リビング・ニーズ特約は、被保険者の余命が６カ月以内と判断された場合に、死亡保険金の一部または全部を受け取れます。",
    answer: 0,
    categoryId: 2,
    num: 16,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-17",
    diff: 2,
    question: "医療保険等に付加される先進医療特約では、療養を受けた日時点において厚生労働大臣により定められている先進医療が給付の対象となる。（21 年９月・改）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 17,
    type: 1,
    lessonNo: 5
  },
  {
    id: "2-18",
    diff: 2,
    question: "払済保険とは、保険料の払い込みを中止して、その時点での解約返戻金相当額をもとに、保険金額を変えずに、一時払いの定期保険に変更するものである。（20 年１月）",
    lecture: "保険金額を変えずに、一時払いの定期保険に変更するのは、延長保険です。",
    answer: 0,
    categoryId: 2,
    num: 18,
    type: 1,
    lessonNo: 6
  },
  {
    id: "2-19",
    diff: 1,
    question: "定期保険特約付終身保険の保険料の払込みを中止して、払済終身保険に変更した場合、元契約に付加していた定期保険特約はそのまま継続する。（20 年９月・改）",
    lecture: "払済保険に変更した場合は、契約を維持することはできますが、一部を除き、特約は消滅します。",
    answer: 0,
    categoryId: 2,
    num: 19,
    type: 1,
    lessonNo: 6
  },
  {
    id: "2-20",
    diff: 2,
    question: "現在加入している生命保険契約を、契約転換制度を利用して、新たな契約に転換する場合、転換後の保険料は、転換前の契約の保険料率が引き続き適用される。（19 年９月）",
    lecture: "契約転換制度は、新たな契約として扱われます。そのため、転換後の保険料は転換時の年齢や保険料率で計算されます。",
    answer: 0,
    categoryId: 2,
    num: 20,
    type: 1,
    lessonNo: 6
  },
  {
    id: "2-21",
    diff: 1,
    question: "本年中に契約した生命保険に付加されている傷害特約に係る保険料は、介護医療保険料控除の対象となる。（19 年１月）",
    lecture: "2012 年以降の生命保険料控除では、傷害特約、災害割増特約など身体の傷害のみに基因して保険金が支払われる保険の保険料は控除対象外です。",
    answer: 0,
    categoryId: 2,
    num: 21,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-22",
    diff: 2,
    question: "生命保険の入院特約に基づき、被保険者が病気で入院したことにより被保険者が受け取った入院給付金は、非課税である。（20 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 22,
    type: 1,
    lessonNo: 7
  },
  {
    id: "2-23",
    diff: 1,
    question: "養老保険の福利厚生プランでは、契約者（＝保険料負担者）を法人、被保険者を従業員全員、死亡保険金受取人を被保険者の遺族、満期保険金受取人を法人とすることにより、支払保険料の全額を福利厚生費として損金の額に算入することができる。（19 年５月）",
    lecture: "福利厚生プラン（ハーフタックスプラン）は、受取人等の要件が満たされていれば、支払保険料の半分を資産計上し、残り半分は福利厚生費として損金算入することができます。",
    answer: 0,
    categoryId: 2,
    num: 23,
    type: 1,
    lessonNo: 8
  },
  {
    id: "2-24",
    diff: 1,
    question: "火災保険において、保険金額が保険価額に満たない保険を一部保険という。（18 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 24,
    type: 1,
    lessonNo: 9
  },
  {
    id: "2-25",
    diff: 1,
    question: "民法および失火の責任に関する法律（失火責任法）によれば、借家人が軽過失によって借家と隣家を焼失させた場合、借家の家主に対して損害賠償責任を負わない。（ 20 年９月・改）",
    lecture: "失火責任法では、借家人が軽過失によって（＝重過失なく）借家と隣家を焼 失させた場合、隣家に対しては損害賠償責任を負いませんが、借家の家主に対しては損害賠償責任を負います。",
    answer: 0,
    categoryId: 2,
    num: 25,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-26",
    diff: 1,
    question: "居住用建物および家財を対象とした火災保険では、地震もしくは噴火またはこれらによる津波を原因とする損害は、補償の対象とならない。（21 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 26,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-27",
    diff: 1,
    question: "地震保険の保険料の割引制度には、「建築年割引」「耐震等級割引」「免震建 築物割引」「耐震診断割引」があり、割引率は「耐震等級割引（耐震等級 ３）」および「免震建築物割引」の 50％ が最大となる。なお、それぞれの割引制度の重複適用はできない。（19年1月・改）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 27,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-28",
    diff: 1,
    question: "地震保険では、保険の対象である居住用建物または家財の損害の程度が「全損」「大半損」「小半損」のいずれかに該当した場合に限り、保険金が支払われる。（21 年９月・改）",
    lecture: "地震保険では、損害の程度を「全損」「大半損」「小半損」「一部損」の４区分に分けて保険金の支払いをします。",
    answer: 0,
    categoryId: 2,
    num: 28,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-29",
    diff: 2,
    question: "自動車損害賠償責任保険において、被害者１人あたりの保険金の支払限度額は、死亡の場合は 3,000 万円、後遺障害の場合は 5,000 万円である。（19 年１月）",
    lecture: "自賠責保険の支払限度額は、被害者１人あたり死亡の場合は 3,000 万円、後遺障害の場合は 4,000 万円です。",
    answer: 0,
    categoryId: 2,
    num: 29,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-30",
    diff: 2,
    question: "自動車保険の人身傷害補償保険では、被保険者が自動車事故により負傷した場合、自己の過失割合にかかわらず、保険金額の範囲内で治療費や休業損害などの実際の損害額が補償される。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 30,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-31",
    diff: 1,
    question: "自動車保険の車両保険では、一般に、洪水により自動車が水没したことによって被る損害は補償の対象とならない。（22 年１月）",
    lecture: "洪水や高潮による水没は、車両保険の補償対象です。なお、津波による水没は特約がなければ補償対象外です。",
    answer: 0,
    categoryId: 2,
    num: 31,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-32",
    diff: 2,
    question: "普通傷害保険（特約付帯なし）では、一般に、被保険者が細菌性食中毒により入院した場合は、保険金支払の対象となる。（21 年９月）",
    lecture: "細菌性食中毒による損害は、普通傷害保険（特約なし）では補償対象外です。国内旅行傷害保険や海外旅行傷害保険では補償されます。",
    answer: 0,
    categoryId: 2,
    num: 32,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-33",
    diff: 2,
    question: "家族傷害保険の被保険者の範囲には、被保険者本人と生計をともにする別居の未婚の子は含まれない。（21 年５月・改）",
    lecture: "家族の範囲には、被保険者本人、配偶者、生計を一にする（ともにする）同居親族のほかにも、生計を一にする別居の未婚の子も含まれます。",
    answer: 0,
    categoryId: 2,
    num: 33,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-34",
    diff: 2,
    question: "海外旅行傷害保険は、国内空港を出発してから国内空港に帰着するまでが補償の対象となるため、住居から国内空港に移動する間に負ったケガは補償の対象とならない。（20 年１月）",
    lecture: "海外旅行傷害保険の補償は、家を出てから帰宅するまでが補償の対象です。そのため、住居から空港へ移動する間に負ったケガも補償されます。",
    answer: 0,
    categoryId: 2,
    num: 34,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-35",
    diff: 2,
    question: "個人賠償責任保険（特約）では、被保険者が、業務中に自転車で歩行者に衝突してケガをさせてしまい、法律上の損害賠償責任を負うことによって被る損害は、補償の対象とならない。（22 年５月・改）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 35,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-36",
    diff: 2,
    question: "店舗の床に清掃時の水が残っていたため、顧客が転倒・負傷した場合に、顧客に対して法律上の損害賠償責任を負うことによって被る損害を補償する保険として、生産物賠償責任保険（PL 保険）がある。（21 年９月・改）",
    lecture: "店舗内での事故により、顧客に対して損害賠償責任を負うリスクに対しては、施設所有（管理）者賠償責任保険が適しています。",
    answer: 0,
    categoryId: 2,
    num: 36,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-37",
    diff: 2,
    question: "スーパーマーケットを経営する企業が、店舗内で調理・販売した食品が原因で食中毒を発生させ、顧客に対して法律上の損害賠償責任を負うことによって被る損害を補償する保険として、施設所有（管理）者賠償責任保険がある。（22 年５月・改）",
    lecture: "店舗内で調理等した食品は生産物にあたるため、食中毒による損害賠償責任リスクに対しては、生産物賠償責任保険（PL保険）が適しています。",
    answer: 0,
    categoryId: 2,
    num: 37,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-38",
    diff: 1,
    question: "ホテルを運営する企業が、クロークで預かる顧客の荷物の紛失・盗難リスクの補償に備えるために、受託者賠償責任保険に加入した。（19 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 38,
    type: 1,
    lessonNo: 10
  },
  {
    id: "2-39",
    diff: 2,
    question: "自動車事故でケガを負い、相手方が加入していた自動車保険の対人賠償保険から受け取った保険金は、非課税とされる。（21 年１月・改）",
    lecture: null,
    answer: 1,
    categoryId: 2,
    num: 39,
    type: 1,
    lessonNo: 11
  },
  {
    id: "2-40",
    diff: 2,
    question: "所得税において、個人が自宅建物について支払う地震保険の保険料は、５万円を限度として年間支払保険料の２分の１相当額が地震保険料控除の対象となる。（21 年１月）",
    lecture: "所得税における地震保険料控除の限度額は最高 5 万円で、年間支払保険料の全額が控除対象です。2 分の 1 相当額（最高 2 万 5,000 円）が控除されるのは住民税です。",
    answer: 0,
    categoryId: 2,
    num: 40,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-1",
    diff: 2,
    question: "一定期間に国内で生産された財やサービスの合計額から物価変動の影響を取り除いた指標を名目 GDPという。（21 年１月）",
    lecture: "一定期間内に国内で生産された財やサービスの付加価値の合計をGDP（国内総生産）といい、物価変動の影響を含んだGDPを名目GDP、名目GDPから物価変動の影響を取り除いたGDPを実質 GDPといいます。",
    answer: 0,
    categoryId: 3,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-2",
    diff: 2,
    question: "景気動向指数において、完全失業率は、遅行系列に採用されている。（24 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-3",
    diff: 3,
    question: "全国の世帯が購入する家計に係る財およびサービスの価格等を総合した物価の変動を時系列的に測定する消費者物価指数は、総務省が公表している。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-4",
    diff: 1,
    question: "マネーストック統計は、中央政府や地方公共団体を除く経済主体が保有する通貨量の残高を集計したものであり、日本銀行が毎月公表している。（19 年９月）",
    lecture: "マネーストックは、中央政府と金融機関を除きます。日本銀行が毎月公表しています。",
    answer: 0,
    categoryId: 3,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-5",
    diff: 2,
    question: "米国の市場金利が上昇し、同時に日本の市場金利が低下することは、米ドルと円の為替相場においては、一般に、米ドル安、円高の要因となる。（20 年９月）",
    lecture: "市場金利が上昇している国の通貨は人気があるので高くなり、市場金利が低下している国の通貨は人気がないので低くなると理解しましょう。",
    answer: 0,
    categoryId: 3,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "3-6",
    diff: 2,
    question: "日本銀行による金融引き締め対策は、一般に、日本の株式市場における株価の上昇要因となる。（18 年５月）",
    lecture: "日本銀行の金融引き締め対策とは、売りオペにより市中の資金量を減少させることなので、金利は上昇しやすくなり、株価は下落しやすくなります。",
    answer: 0,
    categoryId: 3,
    num: 6,
    type: 1,
    lessonNo: 2
  },
  {
    id: "3-7",
    diff: 3,
    question: "日本銀行の公開市場操作による売りオペレーションは、市中の資金量を増加させ、金利の低下を促す効果がある。（19 年５月・改）",
    lecture: "日本銀行の公開市場操作の売りオペは、市中の資金量を減少させ、金利の上昇を促す効果があります。逆に買いオペは市中の資金量を増加させ、金利の低下を促す効果があります。",
    answer: 0,
    categoryId: 3,
    num: 7,
    type: 1,
    lessonNo: 2
  },
  {
    id: "3-8",
    diff: 3,
    question: "預金保険制度の対象金融機関に預け入れた決済用預金は、預入金額にかかわらず、その全額が預金保険制度による保護の対象となる。（22 年 9 月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 8,
    type: 1,
    lessonNo: 3
  },
  {
    id: "3-9",
    diff: 2,
    question: "日本国内に本店のある銀行の国内支店に預け入れた外貨預金は、元本1,000 万円までとその利息が預金保険制度による保護の対象となる。（24年1月）",
    lecture: "外貨預金、譲渡性預金は対象外、国内銀行の海外支店、外国銀行の日本支店も対象外です。",
    answer: 0,
    categoryId: 3,
    num: 9,
    type: 1,
    lessonNo: 3
  },
  {
    id: "3-10",
    diff: 3,
    question: "日本投資者保護基金は、会員である金融商品取引業者が破綻し、分別管理の義務に違反したことによって、一般顧客から預託を受けていた有価証券・金銭を返還することができない場合、一定の範囲の取引を対象に一般顧客１人につき 1,300 万円を上限に金銭による補償を行う。（22 年 5 月）",
    lecture: "証券会社が破綻したときは、日本投資者保護基金により１人 1,000 万円まで補償されます。",
    answer: 0,
    categoryId: 3,
    num: 10,
    type: 1,
    lessonNo: 3
  },
  {
    id: "3-11",
    diff: 2,
    question: "金融商品の販売にあたって、金利、通貨の価格、金融商品市場における相場その他の指標に係る変動を直接の原因として元本欠損が生ずるおそれがあるときは、その旨および当該指標などについて顧客に説明することが、消費者契約法で義務づけられている。（19 年５月）",
    lecture: "設問で説明されている内容を義務づけているのは金融サービス提供法です。金融サービス提供法による金融商品販売業者等に対する規制としては、ほかに、顧客に対する重要事項の説明義務、断定的判断の提供による勧誘の禁止などがあります。",
    answer: 0,
    categoryId: 3,
    num: 11,
    type: 1,
    lessonNo: 4
  },
  {
    id: "3-12",
    diff: 3,
    question: "元金 1,000,000 円を年利１％の１年複利で２年間運用した場合の元利合計金額（税金や手数料などを考慮しない）は、1,020,000 円である。（19 年１月）",
    lecture: "1,000,000 円 × 1.01 × 1.01 ＝ 1,020,100 円",
    answer: 0,
    categoryId: 3,
    num: 12,
    type: 1,
    lessonNo: 5
  },
  {
    id: "3-13",
    diff: 3,
    question: "個人向け国債の金利の下限は、年 0.05％である。（20 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 13,
    type: 1,
    lessonNo: 6
  },
  {
    id: "3-14",
    diff: 2,
    question: "一般に、市場金利が上昇すると債券価格は上昇し、市場金利が低下すると債券価格は下落する。（21年9月）",
    lecture: "一般的に、市場金利が上昇すると低い金利の債券が売られるので、債券価格は下落します。逆に、市場金利が低下すると債券価格は上昇します。",
    answer: 0,
    categoryId: 3,
    num: 14,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-15",
    diff: 2,
    question: "他の条件が同じであれば、償還期限までの期間の長い債券の方が短い債券よりも価格変動幅は小さくなる。（15 年９月）",
    lecture: "償還期限が近づくと、債券価格は額面金額に収斂していきます。言い換えれば、償還期限までの期間が長い債券のほうが価格変動幅は大きくなります。",
    answer: 0,
    categoryId: 3,
    num: 15,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-16",
    diff: 3,
    question: "債券の信用格付とは、債券やその発行体の信用評価を記号等で示したものであり、一般に、BBB（トリプルビー）格相当以上の格付が付された債券は、投資適格債とされる。（23年9月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 16,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-17",
    diff: 3,
    question: "残存期間や表面利率（クーポンレート）などのほかの条件が同一であれば、一般に、格付けの高い債券ほど安全性が高いため、利回りが高くなる。（19 年９月）",
    lecture: "発行条件などが同一であれば、一般的に、格付けが高い（安全性が高いと評価されている）債券は人気があるので、欲しい人が多く、価格が高くなり、利回りは低くなります。格付けが低い債券はその逆で、利回りが高くなります。",
    answer: 0,
    categoryId: 3,
    num: 17,
    type: 1,
    lessonNo: 7
  },
  {
    id: "3-18",
    diff: 2,
    question: "証券取引所での株式の売買において、ある銘柄に価格の異なる複数の買い指値注文がある場合は、指値の安い注文から優先して売買が成立する。（19 年１月）",
    lecture: "指値注文は不利な注文から優先されます。したがって買い注文では高い注文から、売り注文では安い注文が優先されます。",
    answer: 0,
    categoryId: 3,
    num: 18,
    type: 1,
    lessonNo: 8
  },
  {
    id: "3-19",
    diff: 2,
    question: "国内の証券取引所に上場している内国株式を普通取引により売買する場合、約定日の翌営業日に決済が行われる。（22 年５月）",
    lecture: "約定日を含めて 3 営業日目に決済（受渡し）が行われます。",
    answer: 0,
    categoryId: 3,
    num: 19,
    type: 1,
    lessonNo: 8
  },
  {
    id: "3-20",
    diff: 2,
    question: "日経平均株価は、東京証券取引所プライム市場に上場している内国普通株式の全銘柄を対象とする株価指数であり、時価総額の大きい銘柄（大型株）の値動きの影響を受けやすいという特徴がある。（19 年９月・改）",
    lecture: "日経平均株価は東京証券取引所プライム市場に上場する代表的な 225 銘柄を対象とする修正平均株価であり、株価が高い銘柄の影響を受けやすいとされます。",
    answer: 0,
    categoryId: 3,
    num: 20,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-21",
    diff: 3,
    question: "X社の株価が 1,200 円、1 株あたり純利益が 80 円、1 株あたり純資産が 800円のとき、X社のPERは 1.5 倍、PBRは 15 倍である。（21 年１月）",
    lecture: "PERは株価÷ 1 株あたり純利益で求めるので、1,200 円÷ 80 円＝ 15 倍、PBRは株価÷ 1 株あたり純資産で求めるので、1,200 円÷ 800 円＝ 1.5 倍となります。",
    answer: 0,
    categoryId: 3,
    num: 21,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-22",
    diff: 3,
    question: "ROEは、自己資本に対する当期純利益の割合を示す投資指標であり、これが低いほど、会社が自己資本を活用して効率良く利益をあげていることを示す。（20 年９月・改）",
    lecture: "ROEは高いほど、会社が自己資本を活用して効率よく利益をあげていることを示します。",
    answer: 0,
    categoryId: 3,
    num: 22,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-23",
    diff: 3,
    question: "Ｘ社の株価が 1,200 円、１株あたり純利益が 36 円、１株あたり年間配当金が 24 円である場合、Ｘ社株式の配当利回りは 3％である。（19 年 5 月・改）",
    lecture: "配当利回り＝ 1 株あたりの年間配当金÷株価× 100 で求めるので、24 円÷1,200 円× 100=２％となります。",
    answer: 0,
    categoryId: 3,
    num: 23,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-24",
    diff: 3,
    question: "配当性向とは、当期純利益に占める配当金総額の割合を示す指標である。（22 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 24,
    type: 1,
    lessonNo: 9
  },
  {
    id: "3-25",
    diff: 3,
    question: "投資信託約款に株式を組み入れることができる旨の記載がある証券投資信託は、株式をいっさい組み入れていなくても株式投資信託に分類される。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 25,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-26",
    diff: 2,
    question: "投資信託の運用管理費用（信託報酬）は、投資信託を購入する際に年間分を前払いする必要がある。（20 年１月）",
    lecture: "信託報酬は、信託財産から日々差し引かれます。",
    answer: 0,
    categoryId: 3,
    num: 26,
    type: 1,
    lessonNo: 10
  },
  {
    id: "3-27",
    diff: 3,
    question: "投資信託におけるパッシブ運用は、経済環境や金利動向などをふまえ、ベンチマークを上回る運用成果をめざす運用手法である。（19 年１月）",
    lecture: "パッシブ運用とはベンチマーク（評価対象となる株価指数など）に連動する 運用成果をめざします。ベンチマークを上回る運用成果を目指すのがアクティブ運用です。",
    answer: 0,
    categoryId: 3,
    num: 27,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-28",
    diff: 3,
    question: "インデックス型投資信託は、日経平均株価などの特定の指標に連動するよう運用される投資信託である。（21 年 1 月・改）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 28,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-29",
    diff: 3,
    question: "投資信託におけるバリュー運用は、企業の成長性が市場平均よりも高いと見込まれる銘柄に投資する運用手法である。（19 年 5 月）",
    lecture: "投資信託の運用手法には、将来の成長（グロース）が見込める銘柄を選ぶグロース運用や、利益や資産価値などからみて割安（バリュー）な銘柄に投資するバリュー運用があります。",
    answer: 0,
    categoryId: 3,
    num: 29,
    type: 1,
    lessonNo: 11
  },
  {
    id: "3-30",
    diff: 3,
    question: "上場投資信託（ETF）は、証券取引所に上場され、上場株式と同様に指値注文や成行注文により売買することができる。（21 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 30,
    type: 1,
    lessonNo: 11
  },

  {
    id: "3-31",
    diff: 2,
    question: "為替予約を締結していない外貨定期預金において、満期時の為替レートが預入時の為替レートに比べて円安になれば、当該外貨定期預金の円換算の利回りは高くなる。（22 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 31,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-32",
    diff: 2,
    question: "外貨預金の払戻時において、預金者が外貨を円貨に換える場合に適用される為替レートは、預入金融機関が提示するTTSである。（19 年５月）",
    lecture: "預金者が外貨を円にするときのレートはTTBです。",
    answer: 0,
    categoryId: 3,
    num: 32,
    type: 1,
    lessonNo: 12
  },
  {
    id: "3-33",
    diff: 2,
    question: "A 資産の期待収益率が 2.0％、B資産の期待収益率が 4.0%の場合に、A資産を 40%、B資産を 60％の割合で組み入れたポートフォリオの期待収益率は 3.0％となる。（19 年１月）",
    lecture: "ポートフォリオ全体の期待収益率は各資産の「収益率×投資割合」を合計して求めます。（2.0％× 40％）＋（4.0％× 60％）＝ 3.2％となります。",
    answer: 0,
    categoryId: 3,
    num: 33,
    type: 1,
    lessonNo: 13
  },
  {
    id: "3-34",
    diff: 3,
    question: "相関係数が 0 である２資産に投資するポートフォリオにおいては、両資産が同一の値動きをするため、分散投資によるリスク低減効果は得られない。（19 年９月）",
    lecture: "相関係数は、「０」を中心として、「−１」〜「＋１」の範囲で表されます。相関係数が「＋１」でない限り、リスク低減効果はあります。",
    answer: 0,
    categoryId: 3,
    num: 34,
    type: 1,
    lessonNo: 13
  },
  {
    id: "3-35",
    diff: 3,
    question: "オプション取引において、特定の商品を将来の一定期日（まで）に、あらかじめ決められた価格（権利行使価格）で売る権利のことをコール・オプションという。（20 年１月）",
    lecture: "オプション取引とは、買う権利や売る権利を売買する取引のことをいいます。買う権利はコール・オプション、売る権利はプット・オプションといいます。",
    answer: 0,
    categoryId: 3,
    num: 35,
    type: 1,
    lessonNo: 13
  },
  {
    id: "3-36",
    diff: 3,
    question: "個人が国内において支払を受ける預貯金の利子は、原則として、20.315％（所得税 15.315％、住民税 5％）の税率により源泉徴収等され、課税関係が終了する。（22 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 36,
    type: 1,
    lessonNo: 14
  },
  {
    id: "3-37",
    diff: 3,
    question: "追加型の国内公募株式投資信託の収益分配金のうち、元本払戻金（特別分配金）は非課税となる。（18年9月）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 37,
    type: 1,
    lessonNo: 14
  },
  {
    id: "3-38",
    diff: 2,
    question: "追加型株式投資信託を基準価額１万 800 円で１万口購入した後、最初の決算時に１万口あたり 300 円の収益分配金が支払われ、分配落ち後の基準価額が１万 600 円となった場合、その収益分配金のうち、普通分配金は 200 円であり、元本払戻金（特別分配金）は 100 円となる。（20 年１月）",
    lecture: "追加型株式投資信託の分配金は、利益から支払われ、課税対象となる「普通分配金」と、元本の払い戻しのため非課税となる「特別分配金（元本払戻金）」があります。300 円（収益分配金）＋ 10,600 円（分配落ち後の基準価額）＝ 10,900 円10,900 円 − 10,800 円（購入時の基準価額）＝ 100 円（普通分配金）300 円（収益分配金）− 100 円＝ 200 円（特別分配金）となります。",
    answer: 0,
    categoryId: 3,
    num: 38,
    type: 1,
    lessonNo: 14
  },
  {
    id: "3-39",
    diff: 3,
    question: "新 NISA のつみたて投資枠において、国債や社債は投資対象商品ではない。（21 年５月・改）",
    lecture: null,
    answer: 1,
    categoryId: 3,
    num: 39,
    type: 1,
    lessonNo: 14
  },
  {
    id: "3-40",
    diff: 3,
    question: "新NISA のつみたて投資枠を利用して公募株式投資信託等を購入することができる限度額（非課税投資枠）は、年間 40 万円である。（23年1月・改）",
    lecture: "年間 120 万円です。",
    answer: 0,
    categoryId: 3,
    num: 40,
    type: 1,
    lessonNo: 14
  },
  {
    id: "4-1",
    diff: 1,
    question: "税金には国税と地方税があるが、相続税は地方税に該当する。（21 年１月・改）",
    lecture: "相続税は国税です。所得税、法人税、贈与税なども国税です。",
    answer: 0,
    categoryId: 4,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-2",
    diff: 2,
    question: "所得税において、自己の生活の用に供する家具や衣服（１個または１組の価額が 30 万円を超える貴金属、美術工芸品などは該当しない）を譲渡したことによる所得は、非課税所得とされる。（18 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-3",
    diff: 2,
    question: "電車・バス等の交通機関を利用して通勤している給与所得者が、勤務先から受ける通勤手当は、所得税法上、月額 10 万円を限度に非課税とされる。（21 年９月）",
    lecture: "給与所得者の通勤手当は、月額 15 万円を限度に非課税とされます。",
    answer: 0,
    categoryId: 4,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-4",
    diff: 2,
    question: "所得税において、医療保険の被保険者が病気で入院したことにより受け取った入院給付金は、非課税である。（22 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-5",
    diff: 1,
    question: "所得税法における居住者（非永住者を除く）は、原則として、国内で生じた所得について所得税の納税義務は生じるが、国外で生じた所得について所得税の納税義務は生じない。（19 年５月）",
    lecture: "居住者（非永住者を除く）は、国内外を問わず全ての所得が課税対象であり、納税義務が生じます。",
    answer: 0,
    categoryId: 4,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-6",
    diff: 1,
    question: "所得税においては、原則として、超過累進税率が採用されており、課税所得金額が多くなるに従って税率が高くなる。（20 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 6,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-7",
    diff: 2,
    question: "総所得金額とは、総合課税の対象となる所得金額を合算したもので、損益通算前の金額である。",
    lecture: "総所得金額は、損益通算後の金額をいいます。",
    answer: 0,
    categoryId: 4,
    num: 7,
    type: 1,
    lessonNo: 1
  },
  {
    id: "4-8",
    diff: 1,
    question: "個人が国内において支払いを受ける預貯金の利子は、原則として、20 . 315 ％ の税率により所得税および復興特別所得税と住民税が源泉徴収等され、課税関係が終了する。（ 22 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 8,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-9",
    diff: 1,
    question: "不動産の賃貸に伴い受け取った敷金のうち、不動産の貸付期間が終了した際に賃借人に返還を要するものは、受け取った年分の不動産所得の金額の計算上、総収入金額には算入しない。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 9,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-10",
    diff: 3,
    question: "所得税において、事業的規模で行われている賃貸マンションの貸付けによる所得は、事業所得となる。（22 年５月）",
    lecture: "賃貸マンションの貸付けによる所得は、事業的規模か否かを問わず不動産所得になります。",
    answer: 0,
    categoryId: 4,
    num: 10,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-11",
    diff: 1,
    question: "所得税における事業所得の金額は、「（その年中の事業所得に係る総収入金額－必要経費）×２分の１」の算式により計算される。（18 年５月）",
    lecture: "事業所得の金額は、「その年中の事業所得に係る総収入金額－必要経費」で求められ、算式に 2 分の１は用いません。",
    answer: 0,
    categoryId: 4,
    num: 11,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-12",
    diff: 2,
    question: "土地は、減価償却資産ではない。（20 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 12,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-13",
    diff: 1,
    question: "所得税において、新たに取得した建物（鉱業用減価償却資産等を除く）に係る減価償却の方法は、定率法である。（21 年１月・改）",
    lecture: "1998 年 4 月 1 日以降、新たに取得した建物（鉱業用減価償却資産等を除く）の減価償却の方法は、定額法です。",
    answer: 0,
    categoryId: 4,
    num: 13,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-14",
    diff: 2,
    question: "確定拠出年金の個人型年金の老齢給付金を一時金で受け取った場合、当該老齢給付金は、退職所得として所得税の課税対象となる。（21 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 14,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-15",
    diff: 2,
    question: "給与所得者が35年間勤務した会社を定年退職し、退職金3,000万円の支給を受けた場合、退職所得の金額の計算上、退職所得控除額は800万円＋40万円×（35年－20年）＝1,400万円となる。",
    lecture: "勤続年数35年の場合の退職所得控除額は、800万円＋70万円×（35年－20年）＝1,850万円です。",
    answer: 0,
    categoryId: 4,
    num: 15,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-16",
    diff: 2,
    question: "個人が法人からの贈与により取得した財産については、原則として贈与税の課税対象となり、所得税は課されない。（20 年９月）",
    lecture: "法人からの贈与財産は、一時所得（雇用関係があれば給与所得）に分類され、所得税および住民税の課税対象です。",
    answer: 0,
    categoryId: 4,
    num: 16,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-17",
    diff: 3,
    question: "所得税における一時所得に係る総収入金額が 500 万円で、その収入を得るために支出した金額が 400 万円である場合、総所得金額に算入される一時所得の金額は、50 万円である。（21 年５月）",
    lecture: "設問の一時所得は「500 万円－ 400 万円－特別控除 50 万円＝ 50 万円」ですが、総所得金額に算入する際には 2 分の１するため、総所得金額に算入される一時所得は 25 万円です。",
    answer: 0,
    categoryId: 4,
    num: 17,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-18",
    diff: 3,
    question: "所得税において、公的年金等に係る雑所得の金額は、その年中の公的年金等の収入金額から公的年金等控除額を控除して計算する。（19 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 18,
    type: 1,
    lessonNo: 2
  },
  {
    id: "4-19",
    diff: 1,
    question: "所得税において、不動産所得、事業所得、山林所得、譲渡所得の金額の計算上生じた損失の金額は、一定の場合を除き、他の所得の金額と損益通算することができる。（22 年１月・改）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 19,
    type: 1,
    lessonNo: 3
  },
  {
    id: "4-20",
    diff: 2,
    question: "不動産所得の金額の計算上生じた損失の金額のうち、不動産所得を生ずべき土地等を取得するために要した負債の利子の額に相当する部分の金額は、損益通算の対象とならない。（19 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 20,
    type: 1,
    lessonNo: 3
  },
  {
    id: "4-21",
    diff: 2,
    question: "上場株式を譲渡したことによる譲渡所得の金額の計算上生じた損失の金額は、確定申告をすることにより、不動産所得や事業所得などの他の所得金額と損益通算することができる。（22 年９月）",
    lecture: "上場株式等の譲渡損失は、確定申告をすることで一定の配当所得や利子所得とは損益通算できますが、それ以外の所得（不動産所得等）とは損益通算できません。",
    answer: 0,
    categoryId: 4,
    num: 21,
    type: 1,
    lessonNo: 3
  },
  {
    id: "4-22",
    diff: 2,
    question: "所得税において、納税者の合計所得金額が 2,400 万円以下である場合、基礎控除の額は、48 万円である。（22 年９月・改）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 22,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-23",
    diff: 2,
    question: "所得税において、生計を一にする配偶者の合計所得金額が 48 万円を超える場合、配偶者控除の適用を受けることはできない。（23年5月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 23,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-24",
    diff: 2,
    question: "所得税において、控除対象扶養親族のうち、その年の 12 月 31 日時点の年齢が 18 歳以上 22 歳未満である者は、特定扶養親族に該当する。（21 年５月・改）",
    lecture: "特定扶養親族の年齢は、その年の 12 月 31 日時点で 19 歳以上 23 歳未満です。",
    answer: 0,
    categoryId: 4,
    num: 24,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-25",
    diff: 1,
    question: "所得税法上、控除対象扶養親族のうち、その年の 12 月 31 日現在の年齢が70 歳以上の者は、老人扶養親族に該当する。（21 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 25,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-26",
    diff: 2,
    question: "夫が生計を一にする妻の負担すべき国民年金の保険料を支払った場合、その支払った金額は、夫に係る所得税の社会保険料控除の対象となる。（23 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 26,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-27",
    diff: 2,
    question: "夫が生計を一にする妻に係る確定拠出年金の個人型年金の掛金を負担した場合、その負担した掛金は、夫に係る所得税の小規模企業共済等掛金控除の対象となる。（22 年１月）",
    lecture: "小規模企業共済等掛金控除は、確定拠出年金の加入者が掛金を拠出した場合に受けられるため、妻の掛金を夫が負担しても、夫の控除の対象にはなりません。",
    answer: 0,
    categoryId: 4,
    num: 27,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-28",
    diff: 2,
    question: "確定拠出年金の個人型年金において加入者が拠出した掛金は、その２分の１相当額が小規模企業共済等掛金控除として所得控除の対象となる。（19 年５月）",
    lecture: "個人型確定拠出年金に支払った掛金は、支払った掛金の全額が小規模企業共済等掛金控除の対象になります。",
    answer: 0,
    categoryId: 4,
    num: 28,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-29",
    diff: 2,
    question: "所得税において、人間ドックの受診費用は、その人間ドックによって特に異常が発見されなかった場合であっても、医療費控除の対象となる。（19 年９月）",
    lecture: "人間ドックにより異常が発見されなかった場合の受診費用は、医療費控除の対象にはなりません。",
    answer: 0,
    categoryId: 4,
    num: 29,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-30",
    diff: 1,
    question: "夫が生計を一にする妻に係る医療費を支払った場合、妻の合計所得金額が48 万円を超えるときは、その支払った医療費は夫に係る所得税の医療費控除の対象とならない。（21 年９月）",
    lecture: "医療費控除は、納税者本人や生計を一にする配偶者・その他の親族が支払った医療費であれば、各人の所得金額に関わらず、納税者本人の医療費控除の対象になります。",
    answer: 0,
    categoryId: 4,
    num: 30,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-31",
    diff: 1,
    question: "所得税において、納税者がスイッチOTC 医薬品を購入した場合、所定の要件を満たせば、88,000 円を限度として、その購入費用の全額を医療費控除として総所得金額等から控除することができる。（20 年１月）",
    lecture: "セルフメディケーション税制の控除額は、上限は 88,000 円ですが、OTC医薬品等の購入費用から、保険金等で補填される金額と 12,000 円を差し引いて控除額を求めます。",
    answer: 0,
    categoryId: 4,
    num: 31,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-32",
    diff: 2,
    question: "「ふるさと納税ワンストップ特例制度」の適用を受けるためには、同一年中の寄附金の額の合計額が５万円以下でなければならない。（21 年９月）",
    lecture: "ふるさと納税ワンストップ特例は、寄附金の合計額に関わらず、寄附先が5自治体以内であれば適用を受けられます。",
    answer: 0,
    categoryId: 4,
    num: 32,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-33",
    diff: 1,
    question: "上場不動産投資信託（J-REIT）の分配金は配当所得となり、所得税の配当控除の対象となる。（22 年１月）",
    lecture: "不動産投資信託（J-REIT）の分配金は配当所得ですが、配当控除の適用対象外です。",
    answer: 0,
    categoryId: 4,
    num: 33,
    type: 1,
    lessonNo: 5
  },
  {
    id: "4-34",
    diff: 2,
    question: "所得税において、住宅借入金等特別控除の対象となる新築住宅は、床面積が100 ㎡以上で、かつ、その２分の１以上に相当する部分がもっぱら自己の居住の用に供されるものとされている。（19 年９月）",
    lecture: "住宅借入金等特別控除の対象になる新築住宅は、床面積が原則は 50㎡以上（一定の場合、40㎡以上）で、かつ、床面積の 2 分の 1 以上が自己の居住用であることです。",
    answer: 0,
    categoryId: 4,
    num: 34,
    type: 1,
    lessonNo: 6
  },
  {
    id: "4-35",
    diff: 1,
    question: "住宅ローンを利用してマンションを取得し、所得税の住宅借入金等特別控除の適用を受ける場合、借入金の償還期間は、20 年以上でなければならない。（23年5月）",
    lecture: "住宅借入金等特別控除の適用を受けるには、借入金の償還期間が 10 年以上でなければなりません。",
    answer: 0,
    categoryId: 4,
    num: 35,
    type: 1,
    lessonNo: 6
  },
  {
    id: "4-36",
    diff: 3,
    question: "所得税の確定申告をしなければならない者は、原則として、所得が生じた翌年の２月 16 日から３月 15 日までの間に、納税地の所轄税務署長に対して確定申告書を提出しなければならない。（21 年９月・改）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 36,
    type: 1,
    lessonNo: 7
  },
  {
    id: "4-37",
    diff: 3,
    question: "給与所得者のうち、その年中に支払を受けるべき給与の収入金額が 1,000万円を超える者は、所得税の確定申告をしなければならない。（20 年１月）",
    lecture: "給与所得者でも、給与の収入金額が 2,000 万円を超える者は、確定申告をしなければなりません。",
    answer: 0,
    categoryId: 4,
    num: 37,
    type: 1,
    lessonNo: 7
  },
  {
    id: "4-38",
    diff: 2,
    question: "その年の１月 16 日以後、新たに業務を開始した者が、その年分から所得税の青色申告の適用を受けるためには、その業務を開始した日から２カ月以内に、青色申告承認申請書を納税地の所轄税務署長に提出し、その承認を受けなければならない。（19 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 4,
    num: 38,
    type: 1,
    lessonNo: 7
  },
  {
    id: "4-39",
    diff: 2,
    question: "所得税の計算において、青色申告書を提出した年に生じた純損失の金額は、所定の要件のもと、その損失が生じた年の翌年以降５年間繰り越すことができる。（18 年５月）",
    lecture: "青色申告者の純損失は、翌年以降３年間にわたって繰り越して、各年分の所得金額から控除できます。",
    answer: 0,
    categoryId: 4,
    num: 39,
    type: 1,
    lessonNo: 7
  },
  {
    id: "4-40",
    diff: 1,
    question: "不動産所得のみを有する青色申告者は、その事業の規模にかかわらず、最高65 万円の青色申告特別控除の適用を受けることができる。（21 年１月）",
    lecture: "不動産所得のみの青色申告者が最高 65 万円の青色申告特別控除を受けるには、事業的規模（5 棟 10 室以上）の貸付で、e-Taxによる申告等の要件を満たさなければなりません。",
    answer: 0,
    categoryId: 4,
    num: 40,
    type: 1,
    lessonNo: 7
  },
  {
    id: "5-1",
    diff: 2,
    question: "相続税路線価は、地価公示の公示価格の 80％を価格水準の目安として設定されている。（20 年９月・改）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-2",
    diff: 2,
    question: "土地および家屋に係る固定資産税評価額は、原則として、３年ごとの基準年度において評価替えが行われる。（21 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-3",
    diff: 3,
    question: "土地の登記記録の表題部には、所在や地積など、土地の表示に関する事項が記録される。（19 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-4",
    diff: 1,
    question: "不動産登記には公信力が認められていないため、登記記録上の権利者が真実の権利者と異なっている場合に、登記記録を信じて不動産を購入した者は、原則として、その不動産に対する権利の取得について法的に保護されない。（23年5月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-5",
    diff: 2,
    question: "不動産の登記事項証明書の交付を請求することができる者は、当該不動産の所有者に限られる。（22 年１月）",
    lecture: "登記事項証明書の交付請求は、手数料を納付して申請すれば誰でもできます。",
    answer: 0,
    categoryId: 5,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-6",
    diff: 2,
    question: "不動産の売買契約において、買主が売主に解約手付を交付した場合、売主は、買主が契約の履行に着手するまでは、受領した手付と同額を買主に現実に提供することで、契約の解除をすることができる。（21 年１月・改）",
    lecture: "解約手付をもって解約する場合には、売主は受領した手付の倍額を現実に提供することが必要です。",
    answer: 0,
    categoryId: 5,
    num: 6,
    type: 1,
    lessonNo: 1
  },
  {
    id: "5-7",
    diff: 1,
    question: "宅地建物取引業者が自ら売主、買主が宅地建物取引業者でない宅地または建物の売買契約の締結に際して、売買代金の額の２割を超える額の手付金を受領することができない。（22 年５月・改）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 7,
    type: 1,
    lessonNo: 2
  },
  {
    id: "5-8",
    diff: 2,
    question: "宅地建物取引業法の規定によれば、宅地建物取引業者が依頼者と締結する宅地または建物の売買の媒介契約のうち、専任媒介契約の有効期間は、最長で６カ月である。（19 年１月）",
    lecture: "専任媒介契約の有効期間は最長 3 カ月です。仮に、当事者間で 3 カ月を超える同意があったとしても、超える部分は無効です。",
    answer: 0,
    categoryId: 5,
    num: 8,
    type: 1,
    lessonNo: 2
  },
  {
    id: "5-9",
    diff: 2,
    question: "宅地建物取引業法に規定される宅地または建物の売買の媒介契約のうち、専属専任媒介契約では、依頼者は他の宅地建物取引業者に重ねて媒介の依頼をすることができる。（18 年９月・改）",
    lecture: "依頼者が他の宅地建物取引業者に重ねて依頼できる媒介契約の種類は、一般媒介契約だけです。",
    answer: 0,
    categoryId: 5,
    num: 9,
    type: 1,
    lessonNo: 2
  },
  {
    id: "5-10",
    diff: 2,
    question: "借地借家法において、事業用定期借地権等は、専ら事業の用に供する建物の所有を目的とするものであり、居住の用に供する建物の所有を目的として設定することはできない。（21 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 10,
    type: 1,
    lessonNo: 3
  },
  {
    id: "5-11",
    diff: 2,
    question: "借地借家法の規定によれば、事業用定期借地権等の設定を目的とする契約は公正証書によって締結しなければならない。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 11,
    type: 1,
    lessonNo: 3
  },
  {
    id: "5-12",
    diff: 2,
    question: "借地借家法の規定では、定期建物賃貸借契約（定期借家契約）において、貸主に正当の事由があると認められる場合でなければ、貸主は、借主からの契約の更新の請求を拒むことができないとされている。（20 年９月）",
    lecture: "設問は普通借家契約の説明です。定期借家契約では、契約期間の満了と共に契約は更新されずに終了します。",
    answer: 0,
    categoryId: 5,
    num: 12,
    type: 1,
    lessonNo: 3
  },
  {
    id: "5-13",
    diff: 2,
    question: "建物の区分所有等に関する法律（区分所有法）によれば、規約の変更は、区分所有者および議決権の各５分の４以上の多数による集会の決議によらなければならない。（23年9月・改）",
    lecture: "規約の変更は、区分所有者および議決権の各４分の３以上の多数による集会の決議によらなければなりません。",
    answer: 0,
    categoryId: 5,
    num: 13,
    type: 1,
    lessonNo: 4
  },
  {
    id: "5-14",
    diff: 2,
    question: "建物の区分所有等に関する法律の規定によれば、集会において、区分所有者および議決権の各３分の２以上の多数により、区分所有建物を取り壊し、その敷地上に新たに建物を建築する旨の決議をすることができる。 （18 年１月・改）",
    lecture: "区分所有建物の建替え決議に必要な区分所有者および議決権は、各5分の4以上です。",
    answer: 0,
    categoryId: 5,
    num: 14,
    type: 1,
    lessonNo: 4
  },
  {
    id: "5-15",
    diff: 2,
    question: "都市計画法において、市街化調整区域とは、おおむね 10 年以内に計画的に市街化を図るべき区域である。（21 年５月）",
    lecture: "市街化調整区域は、市街化を抑制すべき区域であり、設問は市街化区域の説明です。",
    answer: 0,
    categoryId: 5,
    num: 15,
    type: 1,
    lessonNo: 5
  },
  {
    id: "5-16",
    diff: 2,
    question: "都市計画法において、市街化区域内で行う開発行為は、その規模にかかわらず、都道府県知事等の許可を受けなければならない。（23 年１月）",
    lecture: "市街化区域では、原則、1,000㎡未満の開発行為は許可不要としています。",
    answer: 0,
    categoryId: 5,
    num: 16,
    type: 1,
    lessonNo: 5
  },
  {
    id: "5-17",
    diff: 1,
    question: "建築基準法の規定によれば、住宅は、工業地域内および準工業地域内においても建築することができる。（19 年５月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 17,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-18",
    diff: 2,
    question: "建築基準法によれば、建築物の敷地が２つの異なる用途地域にわたる場合、その全部について、建築物の用途制限がより厳しい用途地域の建築物の用途に関する規定が適用される。（23年9月）",
    lecture: "建築物の敷地が 2 つの異なる用途地域にまたがる場合は、敷地の過半を属する地域の用途制限が敷地全体に適用されます。",
    answer: 0,
    categoryId: 5,
    num: 18,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-19",
    diff: 2,
    question: "建築基準法の規定によれば、都市計画区域および準都市計画区域内において、建築物の敷地は、原則として幅員４ｍ以上の道路に２ｍ以上接していなければならない。（20 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 19,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-20",
    diff: 2,
    question: "都市計画区域内にある幅員４ｍ未満の道で、建築基準法第 42 条第２項により道路とみなされるものについては、原則として、その中心線からの水平距離で 4 ｍ後退した線がその道路の境界線とみなされる。（23 年１月・改）",
    lecture: "建築基準法第 42 条第 2 項による道路（4m未満の道路）は、原則として、その中心線から水平距離で 2 ｍ後退した線が道路境界線とみなされます。",
    answer: 0,
    categoryId: 5,
    num: 20,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-21",
    diff: 1,
    question: "建築基準法の規定によれば、建蔽率の限度が 80％の近隣商業地域内で、かつ、防火地域内にある耐火建築物については、建蔽率に関する制限の規定は適用されない。（19 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 21,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-22",
    diff: 1,
    question: "建築基準法上、容積率とは、建築物の建築面積の敷地面積に対する割合をいう。（21 年９月）",
    lecture: "設問は建蔽率の説明です。容積率とは、建築物の延べ面積の敷地面積に対する割合です。",
    answer: 0,
    categoryId: 5,
    num: 22,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-23",
    diff: 2,
    question: "幅員６ｍの市道に 12 ｍ接する 200 ㎡の敷地に、建築面積が 120 ㎡、延べ面積が 180 ㎡の２階建ての住宅を建築する場合、この住宅の容積率は、60％となる。（20 年９月）",
    lecture: "容積率（％）は「延べ面積÷敷地面積× 100」で求めます。この住宅の容積率は、180㎡÷ 200㎡× 100 ＝ 90％となります。",
    answer: 0,
    categoryId: 5,
    num: 23,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-24",
    diff: 2,
    question: "建築物が防火地域および準防火地域にわたる場合においては、原則として、その全部について準防火地域内の建築物に関する規定が適用される。（19 年９月・改）",
    lecture: "建築物が防火地域や準防火地域にわたる場合、原則として、厳しい方の規定が適用されるため、設問の場合は防火地域の規定が適用されます。",
    answer: 0,
    categoryId: 5,
    num: 24,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-25",
    diff: 1,
    question: "建築基準法の規定によれば、第一種低層住居専用地域内における建築物の高さは、原則として 10 ｍまたは 20 ｍのうち当該地域に関する都市計画において定められた建築物の高さの限度を超えてはならない。（19 年１月）",
    lecture: "第一種低層住居専用地域に規定される建築物の高さの限度は、原則として10 ｍまたは 12 ｍです。",
    answer: 0,
    categoryId: 5,
    num: 25,
    type: 1,
    lessonNo: 6
  },
  {
    id: "5-26",
    diff: 2,
    question: "所有する農地を自宅の建築を目的として宅地に転用する場合、原則として都道府県知事等の許可が必要であるが、市街化区域内にある農地については、あらかじめ都道府県知事等に届出をすれば都道府県知事等の許可は不要である。（20 年１月・改）",
    lecture: "自己の農地を自己が使用する目的で宅地に転用する場合は、原則として、農地法第 4 条の許可を要しますが、当該農地が市街化区域内であれば、あらかじめ農業委員会への届出で足り、都道府県知事等の許可は不要です。",
    answer: 0,
    categoryId: 5,
    num: 26,
    type: 1,
    lessonNo: 7
  },
  {
    id: "5-27",
    diff: 2,
    question: "不動産取得税は、相続人が不動産を相続により取得した場合には課されない。（20 年１月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 27,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-28",
    diff: 1,
    question: "新築の一戸建て住宅（一般住宅）の取得に対する不動産取得税の課税標準の算定上、「不動産取得税の課税標準の特例」の適用を受けることにより、固定資産税評価額から最高で 1,500 万円を控除することができる。（ 19 年５月）",
    lecture: "不動産取得税の課税標準の特例を受けられる場合は、固定資産税評価額から最高 1,200 万円（一般住宅の場合）を控除することができます。",
    answer: 0,
    categoryId: 5,
    num: 28,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-29",
    diff: 1,
    question: "「住宅用地に対する固定資産税の課税標準の特例」は、自己の居住用家屋の敷地である宅地にのみ適用されるため、賃貸アパートの敷地である宅地については適用されない。（21 年９月）",
    lecture: "「住宅用地」には、賃貸アパートの敷地も含まれ、当該課税標準の特例が適用されます。",
    answer: 0,
    categoryId: 5,
    num: 29,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-30",
    diff: 2,
    question: "固定資産税における小規模住宅用地（住宅用地で住宅１戸あたり 200 ㎡以下の部分）の課税標準については、当該住宅用地に係る固定資産税の課税標準となるべき価格の５分の１の額とする特例がある。（19 年９月）",
    lecture: "当該小規模住宅用地では、固定資産税の課税標準となるべき価格の６分の１の額とする特例があります。",
    answer: 0,
    categoryId: 5,
    num: 30,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-31",
    diff: 2,
    question: "個人が土地・建物を譲渡したことによる譲渡所得の金額の計算において、譲渡した土地・建物の取得費が不明である場合、譲渡収入金額の 10％相当額を取得費とすることができる。（20 年１月・改）",
    lecture: "譲渡した土地・建物の取得費が不明な場合は、譲渡収入金額の５％相当額を取得費とすることができます。",
    answer: 0,
    categoryId: 5,
    num: 31,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-32",
    diff: 1,
    question: "相続により取得した土地について、「相続財産に係る譲渡所得の課税の特例」（相続税の取得費加算の特例）の適用を受けるためには、当該土地を、当該相続の開始があった日の翌日から相続税の申告期限の翌日以後 3 年を経過する日までの間に譲渡しなければならない。（22 年１月・改）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 32,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-33",
    diff: 1,
    question: "譲渡した日の属する年の１月１日において所有期間が５年を超える土地を譲渡した場合、当該譲渡による譲渡所得については、長期譲渡所得に区分される。（18 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 33,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-34",
    diff: 2,
    question: "Ａさんが、取得日が 2019 年 10 月１日の土地を譲渡する場合、その譲渡日が2024 年１月１日以降であれば、当該譲渡は、所得税における長期譲渡所得に区分される。（21 年 1 月・改）",
    lecture: "2024 年１月１日時点では、所有期間が５年以下のため短期譲渡所得に区分されます。",
    answer: 0,
    categoryId: 5,
    num: 34,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-35",
    diff: 2,
    question: "「居住用財産を譲渡した場合の 3,000 万円の特別控除」は、自己が居住していた家屋を配偶者や子に譲渡した場合には、適用を受けることができない。（22 年９月）",
    lecture: null,
    answer: 1,
    categoryId: 5,
    num: 35,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-36",
    diff: 2,
    question: "「居住用財産を譲渡した場合の 3,000 万円の特別控除」の適用を受けるためには、譲渡した居住用財産の所有期間が譲渡した日の属する年の１月１日において 10 年を超えていなければならない。（21 年５月）",
    lecture: "当該特別控除の適用にあたり、その居住用財産の所有期間の長短は問いません。",
    answer: 0,
    categoryId: 5,
    num: 36,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-37",
    diff: 2,
    question: "「被相続人の居住用財産（空き家）に係る譲渡所得の特別控除の特例」の適用を受けるためには、譲渡の対価の額が 5,000 万円以下でなければならない。（20 年９月）",
    lecture: "当該特別控除の適用要件のうち、譲渡の対価の額は 1 億円以下であることが要件です。",
    answer: 0,
    categoryId: 5,
    num: 37,
    type: 1,
    lessonNo: 8
  },
  {
    id: "5-38",
    diff: 2,
    question: "土地の有効活用方式のうち、一般に、土地所有者が土地の全部または一部を拠出し、デベロッパーが建設費等を拠出して、それぞれの出資比率に応じて土地・建物に係る権利を取得する方式を、事業受託方式という。（20 年９月・改）",
    lecture: "設問の手法は、等価交換方式の説明です。",
    answer: 0,
    categoryId: 5,
    num: 38,
    type: 1,
    lessonNo: 9
  },
  {
    id: "5-39",
    diff: 2,
    question: "土地の有効活用において、一般に、土地所有者が入居予定の事業会社から建設資金を借り受けて、事業会社の要望に沿った店舗等を建設し、その店舗等を事業会社に賃貸する手法を、事業用定期借地権方式という。（21 年９月）",
    lecture: "設問の手法は、建設協力金方式の説明です。",
    answer: 0,
    categoryId: 5,
    num: 39,
    type: 1,
    lessonNo: 9
  },
  {
    id: "5-40",
    diff: 2,
    question: "投資総額１億円で購入した賃貸用不動産の年間収入の合計額が 1,000 万円、年間費用の合計額が 350 万円である場合、この投資の純利回り（NOI 利回り）は、10.0％である。（21 年１月・改）",
    lecture: "純利回りの純収益は、年間収入の合計額から年間費用の合計額を差し引いて計算するため、（1,000 万円－ 350 万円）÷ 1 億円× 100 ＝ 6.5％となります。",
    answer: 0,
    categoryId: 5,
    num: 40,
    type: 1,
    lessonNo: 9
  },
  {
    id: "6-1",
    diff: 3,
    question: "相続において、民法上、実子と養子または嫡出子と嫡出子でない子は、相続人としての扱いで区別される。（18 年 9 月）",
    lecture: "相続人としての扱いに区別はなく、同等に扱われます。",
    answer: 0,
    categoryId: 6,
    num: 1,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-2",
    diff: 2,
    question: "普通養子縁組によって養子となった者については、原則として、養子縁組の成立と同時に、実方の父母との法律上の親族関係が終了する。（19 年 9 月）",
    lecture: "実方の父母との法律上の親族関係が終了するのは、特別養子縁組です。",
    answer: 0,
    categoryId: 6,
    num: 2,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-3",
    diff: 3,
    question: "被相続人に配偶者がなく、遺族が被相続人の子と母の計２人である場合、その相続に係る子の法定相続分は３分の２、母の法定相続分は３分の１である。（18 年 5 月）",
    lecture: "子がいるので、相続人は相続の第一順位である子のみとなり、母には法定相続分はありません。",
    answer: 0,
    categoryId: 6,
    num: 3,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-4",
    diff: 3,
    question: "相続人が相続の放棄をするには、原則として、自己のために相続の開始があったことを知った時から 3 カ月以内に、家庭裁判所にその旨を申述しなければならない。（22 年 5 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 4,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-5",
    diff: 3,
    question: "相続税額の計算において、遺産に係る基礎控除額を計算する際の法定相続人の数は、相続人のうちに相続の放棄をした者がいる場合であっても、その放棄がなかったものとしたときの相続人の数とされる。（22年9月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 5,
    type: 1,
    lessonNo: 1
  },
  {
    id: "6-6",
    diff: 3,
    question: "自筆証書遺言を作成する場合において、自筆証書に添付する財産目録については、自書によらずにパソコンで作成しても差し支えない。（20 年 1 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 6,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-7",
    diff: 3,
    question: "公正証書遺言は、証人 2 人以上の立会いのもと、遺言者が遺言の趣旨を公証人に口授し、公証人がそれを筆記して作成される遺言であり、相続開始後に家庭裁判所における検認手続きが不要である。（22 年 5 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 7,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-8",
    diff: 2,
    question: "公正証書遺言の作成においては、証人の立会いが必要であるが、遺言者の推定相続人はその証人となることができない。（21 年 9 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 8,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-9",
    diff: 3,
    question: "遺留分算定の基礎となる財産の価額が 1 億 2,000 万円で、相続人が被相続人の妻、長女、二女の合計 3 人である場合、妻の遺留分の金額は 2,000 万円となる。（18 年 9 月）",
    lecture: "この場合、配偶者と子が相続人なので、相続財産全体の遺留分は相続財産の2 分の 1 となる 6,000 万円で、各人の遺留分は法定相続分割合となります。配偶者遺留分は 2 分の 1 を掛けて、3,000 万円となります。",
    answer: 0,
    categoryId: 6,
    num: 9,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-10",
    diff: 3,
    question: "遺産分割において、共同相続人の 1 人または数人が、遺産の一部または全部を相続により取得し、他の共同相続人に対して生じた債務を金銭などの財産で負担する方法を代償分割という。（19 年 1 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 10,
    type: 1,
    lessonNo: 2
  },
  {
    id: "6-11",
    diff: 3,
    question: "本年中に相続または遺贈により財産を取得した者が、その相続開始前 3 年以内に被相続人からの暦年課税方式により取得した財産があるときは、その財産の相続時における時価により評価した金額を、原則として相続税の課税価格に加算する。（19 年 5 月）",
    lecture: "生前贈与加算の対象となる財産は、贈与時の評価額が相続税の課税価格に加算されます。なお、2027 年以降の相続から段階的に長くなり（３年超）、2031 年以降に発生する相続からは相続開始前 7 年以内が対象となります。2027 年以降、相続税の課税価格に加算される財産は以下のとおりとなります。相続開始前３年以内：贈与時の価額相続開始前３年より前：贈与時の価額の合計額 － 100 万円",
    answer: 0,
    categoryId: 6,
    num: 11,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-12",
    diff: 3,
    question: "法定相続人が４人いる場合、相続税額の計算において、死亡保険金の非課税限度額は「600 万円×法定相続人の数（４人）」の算式により算出する。（20 年 1 月）",
    lecture: "死亡保険金の非課税限度額の計算式は、「 500 万円×法定相続人の数」で算出します。",
    answer: 0,
    categoryId: 6,
    num: 12,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-13",
    diff: 3,
    question: "相続税額の計算上、被相続人が生前に購入した墓碑の購入代金で、相続開始時において未払いであったものは、債務控除の対象となる。（24年1月）",
    lecture: "墓碑は非課税財産なので、債務控除の対象外です。",
    answer: 0,
    categoryId: 6,
    num: 13,
    type: 1,
    lessonNo: 14
  },
  {
    id: "6-14",
    diff: 3,
    question: "相続人が負担した被相続人の葬式の際の香典返戻費用は、相続税の課税価格の計算上、葬式費用として控除することができる。（21 年 5 月）",
    lecture: "香典返戻費用や初七日などの法要費用は債務控除の対象にはなりません。",
    answer: 0,
    categoryId: 6,
    num: 14,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-15",
    diff: 2,
    question: "被相続人の相続開始前に死亡している被相続人の子を代襲して相続人となった被相続人の孫が相続により財産を取得した場合、相続税額の計算上、相続税額の２割加算の対象となる。（19 年 5 月）",
    lecture: "相続税額の 2 割加算の対象となるのは、被相続人の配偶者および１親等の血族（子、父母、代襲相続人となった孫を含む）以外の人（兄弟姉妹など）が相続または遺贈によって財産を取得した場合です。",
    answer: 0,
    categoryId: 6,
    num: 15,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-16",
    diff: 3,
    question: "相続税額の計算における遺産に係る基礎控除額は、「 3,000 万円＋ 500 万円×法定相続人の数」の算式により求められる。（20 年 1 月）",
    lecture: "「3,000 万円＋ 600 万円×法定相続人の数」の算式により求められます。",
    answer: 0,
    categoryId: 6,
    num: 16,
    type: 1,
    lessonNo: 4
  },
  {
    id: "6-17",
    diff: 3,
    question: "相続税の申告書の提出は、原則として、その相続の開始があったことを知った日の翌日から 6 カ月以内にしなければならない。（22 年 9 月）",
    lecture: "原則、相続の開始があったことを知った日の翌日から 10 カ月以内です。",
    answer: 0,
    categoryId: 6,
    num: 17,
    type: 1,
    lessonNo: 5
  },
  {
    id: "6-18",
    diff: 3,
    question: "相続税額の計算において、「配偶者に対する相続税額の軽減」の適用を受けることにより、納付すべき相続税額が算出されない場合、相続税の申告書を提出する必要はない。（21 年 1 月）",
    lecture: "「配偶者に対する相続税額の軽減」の適用を受けることにより納付税額がゼロになったとしても、相続税の申告は必要です。",
    answer: 0,
    categoryId: 6,
    num: 18,
    type: 1,
    lessonNo: 5
  },
  {
    id: "6-19",
    diff: 3,
    question: "相続税は、相続税の申告書の提出期限までに金銭により一時に納付することが原則であるが、所定の要件を満たせば、延納による納付方法も認められる。（19 年 9 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 19,
    type: 1,
    lessonNo: 5
  },
  {
    id: "6-20",
    diff: 3,
    question: "贈与は、当事者の一方が財産を無償で相手方に与える意思表示をすれば、相手方が受諾しなくても、その効力が生じる。（22年1月）",
    lecture: "贈与の効力は、贈与者、受贈者の双方の合意が必要です。",
    answer: 0,
    categoryId: 6,
    num: 20,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-21",
    diff: 2,
    question: "書面による贈与において、財産の取得時期は当該贈与契約の効力が発生した時とされる。（20 年 1 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 21,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-22",
    diff: 2,
    question: "書面によらない贈与は、既に履行が終わった部分を除き、各当事者が解除することができる。（19 年 5 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 22,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-23",
    diff: 2,
    question: "個人が死因贈与によって取得した財産は、課税の対象とならない財産を除き、贈与税の課税対象となる。（20 年 1 月）",
    lecture: "死因贈与とは「死んだら贈与する」契約を締結している（受贈者も承諾している）もので、相続税の課税対象となります。",
    answer: 0,
    categoryId: 6,
    num: 23,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-24",
    diff: 2,
    question: "個人が法人からの贈与により取得した財産は、贈与税の課税対象とならない。（22 年 1 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 24,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-25",
    diff: 3,
    question: "子が父から時価 300 万円の株式を 50 万円で譲渡を受けた場合、原則として父から子への贈与があったものとみなされ、贈与税の課税対象となる。（19 年 9 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 25,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-26",
    diff: 3,
    question: "子が同一年中に父と母のそれぞれから贈与を受けた場合、その年分の暦年課税による贈与税額の計算上、課税価格から控除する基礎控除額は、最高で220 万円である。（20 年 9 月）",
    lecture: "暦年課税における基礎控除額 110 万円は、贈与者ではなく受贈者ごとです。",
    answer: 0,
    categoryId: 6,
    num: 26,
    type: 1,
    lessonNo: 6
  },
  {
    id: "6-27",
    diff: 3,
    question: "贈与税の配偶者控除は、婚姻期間が 20 年以上である配偶者から居住用不動産の贈与または居住用不動産を取得するための金銭の贈与を受け、所定の要件を満たす場合、贈与税の課税価格から贈与税の基礎控除額とは別に1,000 万円を限度として控除することができるものである。（19 年 5 月）",
    lecture: "基礎控除 110 万円とは別に 2,000 万円までを贈与税の課税価格から控除することができます（合計で 2,110 万円が最高限度）。",
    answer: 0,
    categoryId: 6,
    num: 27,
    type: 1,
    lessonNo: 7
  },
  {
    id: "6-28",
    diff: 3,
    question: "「直系尊属から教育資金の一括贈与を受けた場合の贈与税の非課税」の適用を受けた場合、受贈者１人につき 1,000 万円までは贈与税が非課税となる。（19 年 9 月）",
    lecture: "受贈者１人につき 1,500 万円（うち学校等以外への支出は 500 万円が限度）まで贈与税が非課税となります。",
    answer: 0,
    categoryId: 6,
    num: 28,
    type: 1,
    lessonNo: 7
  },
  {
    id: "6-29",
    diff: 3,
    question: "本年中に相続時精算課税制度の適用を受けた場合、特定贈与者ごとに特別控除額として累計 1,500 万円までの贈与には贈与税が課されず、それを超えた部分については一律 20％の税率により贈与税が課される。（21年1月・改）",
    lecture: "2,500 万円までの贈与には贈与税が課されず、それを超えた部分については一律 20％の税率により贈与税が課されます。2024 年以降、特別控除前に年間 110 万円を控除できる制度となります。贈与税額＝｛（課税価格 － 年間 110 万円)－ 特別控除 2,500 万円の残額｝× 20％",
    answer: 0,
    categoryId: 6,
    num: 29,
    type: 1,
    lessonNo: 8
  },
  {
    id: "6-30",
    diff: 3,
    question: "子が父親からの贈与により取得した財産について相続時精算課税の適用を受けた場合、その適用を受けた年以後、子は父親からの贈与により取得した財産について暦年課税を選択することはできない。（21 年 9 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 30,
    type: 1,
    lessonNo: 8
  },
  
  {
    id: "6-31",
    diff: 2,
    question: "贈与税の申告書は、原則として、贈与を受けた年の翌年の 2 月 16 日から３月 15 日までの間に、受贈者の納税地の所轄税務署長に提出しなければならない。（19 年 1 月）",
    lecture: "贈与税の申告期間は、贈与を受けた年の翌年２月１日から３月 15 日までです。",
    answer: 0,
    categoryId: 6,
    num: 31,
    type: 1,
    lessonNo: 9
  },
  {
    id: "6-32",
    diff: 3,
    question: "贈与税の納付については、納期限までに金銭で納付することを困難とする事由があるなど、所定の要件を満たせば、延納または物納によることが認められている。（19 年 1 月）",
    lecture: "贈与税の納付は、所定の要件を満たすと延納は認められますが、物納は認められません。",
    answer: 0,
    categoryId: 6,
    num: 32,
    type: 1,
    lessonNo: 9
  },
  {
    id: "6-33",
    diff: 2,
    question: "国税庁が公表している路線価図において、路線に「 300C」と付されている場合、「C」の記号は、借地権割合が 80％であることを示している。 （21 年 5 月）",
    lecture: "借地権割合は、Aの 90％から 10％刻みで減っていき、Cは 70％であることを示しています。",
    answer: 0,
    categoryId: 6,
    num: 33,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-34",
    diff: 2,
    question: "貸家建付地の相続税評価額は、自用地としての価額× (1 −借地権割合×賃貸割合)の算式により算出される。（21 年 9 月）",
    lecture: "貸家建付地の相続税評価額は、自用地としての価額× (1 －借地権割合×借家権割合×賃貸割合 )の算式により算出されます。",
    answer: 0,
    categoryId: 6,
    num: 34,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-35",
    diff: 2,
    question: "貸家の用に供されている家屋の相続税評価額は、家屋の固定資産税評価額×（１−借家権割合×賃貸割合）の算式により算出される。（18 年 9 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 35,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-36",
    diff: 3,
    question: "特定居住用宅地等に係る「小規模宅地等についての相続税の課税価格の計算の特例」の適用対象面積は、200 ㎡までの部分である。（18 年 1 月）",
    lecture: "特例適用の要件を満たせば、最大 330㎡までの部分が 80％評価減となります。",
    answer: 0,
    categoryId: 6,
    num: 36,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-37",
    diff: 2,
    question: "相続人が相続により取得した宅地が「小規模宅地等についての相続税の課税価格の計算の特例」における特定事業用宅地等に該当する場合、その宅 地のうち 330 ㎡を限度面積として、評価額の 80％ 相当額を減額した金額を、相続税の課税価格に算入すべき価額とすることができる。（22年1月）",
    lecture: "400㎡までを限度面積として、評価額の 80％相当額を減額した金額を、相続税の課税価格に算入すべき価額とすることができます。",
    answer: 0,
    categoryId: 6,
    num: 37,
    type: 1,
    lessonNo: 10
  },
  {
    id: "6-38",
    diff: 1,
    question: "取引相場のない株式の相続税評価において、純資産価額方式とは、評価会社の株式の価額を、評価会社と事業内容が類似した上場会社の株価および配当金額、利益金額、純資産価額を基にして算出する方式である。（20 年 1 月）",
    lecture: "設問は類似業種比準方式の説明です。",
    answer: 0,
    categoryId: 6,
    num: 38,
    type: 1,
    lessonNo: 11
  },
  {
    id: "6-39",
    diff: 1,
    question: "取引相場のない株式の相続税評価において、同族株主以外の株主等が取得した株式については、特例的評価方式である配当還元方式により評価することができる。（19 年 1 月）",
    lecture: null,
    answer: 1,
    categoryId: 6,
    num: 39,
    type: 1,
    lessonNo: 11
  },
  {
    id: "6-40",
    diff: 2,
    question: "相続財産の評価において、相続開始時に保険事故が発生していない生命保険契約に関する権利の価額は、原則として、既払込保険料相当額によって評価する。（19 年 5 月）",
    lecture: "原則、解約返戻金相当額によって評価します。",
    answer: 0,
    categoryId: 6,
    num: 40,
    type: 1,
    lessonNo: 11
  },
  {
    id: "1-j1",
    categoryId: 1, // [カテゴリの番号1~6]
    num: 1, // [連番]
    type: 2, // 必ず2
    lessonNo: 12, // 必ず数字
    lessonNo2: "", // ~(から)や2つ以上ある場合は表示するものを文字列で入力。からがない場合は""
    answer: 2 // 多肢択一の場合のみ入力
  },
  {
    id: "1-j2",
    categoryId: 1,
    num: 2,
    type: 2,
    lessonNo: 15,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "1-j3",
    categoryId: 1,
    num: 3,
    type: 2,
    lessonNo: 4,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "1-j4",
    categoryId: 1,
    num: 4,
    type: 2,
    lessonNo: 1,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "1-j5",
    categoryId: 1,
    num: 5,
    type: 2,
    lessonNo: 1,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "1-j6",
    categoryId: 1,
    num: 6,
    type: 2,
    lessonNo: 2,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "2-j1",
    categoryId: 2,
    num: 1,
    type: 2,
    lessonNo: 4,
    lessonNo2: "",
    answer: 1
  },
  {
    id: "2-j2",
    categoryId: 2,
    num: 2,
    type: 2,
    lessonNo: 2,
    lessonNo2: "5,7",
    answer: 2
  },
  {
    id: "2-j3",
    categoryId: 2,
    num: 3,
    type: 2,
    lessonNo: 2,
    lessonNo2: "",
    answer: 1
  },
  {
    id: "2-j4",
    categoryId: 2,
    num: 4,
    type: 2,
    lessonNo: 8,
    lessonNo2: "",
    answer: 1
  },
  {
    id: "2-j5",
    categoryId: 2,
    num: 5,
    type: 2,
    lessonNo: 5,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "2-j6",
    categoryId: 2,
    num: 6,
    type: 2,
    lessonNo: 7,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "3-j1",
    categoryId: 3,
    num: 1,
    type: 2,
    lessonNo: 10,
    lessonNo2: "8,9",
    answer: 1
  },
  {
    id: "3-j2",
    categoryId: 3,
    num: 2,
    type: 2,
    lessonNo: 9,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "3-j3",
    categoryId: 3,
    num: 3,
    type: 2,
    lessonNo: 3,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "4-j1",
    categoryId: 4,
    num: 1,
    type: 2,
    lessonNo: 3,
    lessonNo2: "2,7",
    answer: 2
  },
  {
    id: "4-j2",
    categoryId: 4,
    num: 2,
    type: 2,
    lessonNo: 2,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "4-j3",
    categoryId: 4,
    num: 3,
    type: 2,
    lessonNo: 2,
    lessonNo2: "",
    answer: 1
  },
  {
    id: "5-j1",
    categoryId: 5,
    num: 1,
    type: 2,
    lessonNo: 6,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "5-j2",
    categoryId: 5,
    num: 2,
    type: 2,
    lessonNo: 6,
    lessonNo2: "",
    answer: 2
  },
  {
    id: "5-j3",
    categoryId: 5,
    num: 3,
    type: 2,
    lessonNo: 8,
    lessonNo2: "",
    answer: 3
  },
  {
    id: "6-j1",
    categoryId: 6,
    num: 1,
    type: 2,
    lessonNo: 8,
    lessonNo2: "1,2,4,9",
    answer: 3
  },
  {
    id: "6-j2",
    categoryId: 6,
    num: 2,
    type: 2,
    lessonNo: 8,
    lessonNo2: "1,4",
    answer: 2
  },
  {
    id: "6-j3",
    categoryId: 6,
    num: 3,
    type: 2,
    lessonNo: 1,
    lessonNo2: "",
    answer: 1
  },
  {
    id: "6-j4",
    categoryId: 6,
    num: 4,
    type: 2,
    lessonNo: 7,
    lessonNo2: "",
    answer: 1
  },
]