
export default [
    {
        "id": 1,
        "name": "ライフプランニングとは",
        "sort": 1,
        "categoryId": 1
    },
    {
        "id": 2,
        "name": "６つの係数",
        "sort": 2,
        "categoryId": 1
    },
    {
        "id": 3,
        "name": "ファイナンシャル・プランニングと倫理",
        "sort": 3,
        "categoryId": 1
    },
    {
        "id": 4,
        "name": "ファイナンシャル・プランニングと関連法規",
        "sort": 4,
        "categoryId": 1
    },
    {
        "id": 5,
        "name": "教育資金計画",
        "sort": 5,
        "categoryId": 1
    },
    {
        "id": 6,
        "name": "住宅資金計画",
        "sort": 6,
        "categoryId": 1
    },
    {
        "id": 7,
        "name": "社会保険の基本",
        "sort": 7,
        "categoryId": 1
    },
    {
        "id": 8,
        "name": "公的医療保険・介護保険",
        "sort": 8,
        "categoryId": 1
    },
    {
        "id": 9,
        "name": "労災保険・雇用保険",
        "sort": 9,
        "categoryId": 1
    },
    {
        "id": 10,
        "name": "公的年金の仕組み",
        "sort": 10,
        "categoryId": 1
    },
    {
        "id": 11,
        "name": "国民年金の仕組み",
        "sort": 11,
        "categoryId": 1
    },
    {
        "id": 12,
        "name": "老齢基礎年金の受給",
        "sort": 12,
        "categoryId": 1
    },
    {
        "id": 13,
        "name": "厚生年金の仕組み",
        "sort": 13,
        "categoryId": 1
    },
    {
        "id": 14,
        "name": "障害給付",
        "sort": 14,
        "categoryId": 1
    },
    {
        "id": 15,
        "name": "遺族給付",
        "sort": 15,
        "categoryId": 1
    },
    {
        "id": 16,
        "name": "企業の年金制度",
        "sort": 16,
        "categoryId": 1
    },
    {
        "id": 17,
        "name": "保険の基本",
        "sort": 1,
        "categoryId": 2
    },
    {
        "id": 18,
        "name": "契約者の保護",
        "sort": 2,
        "categoryId": 2
    },
    {
        "id": 19,
        "name": "保険法と保険業法",
        "sort": 3,
        "categoryId": 2
    },
    {
        "id": 20,
        "name": "生命保険の仕組み",
        "sort": 4,
        "categoryId": 2
    },
    {
        "id": 21,
        "name": "生命保険の商品",
        "sort": 5,
        "categoryId": 2
    },
    {
        "id": 22,
        "name": "生命保険契約",
        "sort": 6,
        "categoryId": 2
    },
    {
        "id": 23,
        "name": "個人の生命保険と税金",
        "sort": 7,
        "categoryId": 2
    },
    {
        "id": 24,
        "name": "法人の生命保険など",
        "sort": 8,
        "categoryId": 2
    },
    {
        "id": 25,
        "name": "損害保険の仕組み",
        "sort": 9,
        "categoryId": 2
    },
    {
        "id": 26,
        "name": "損害保険の種類",
        "sort": 10,
        "categoryId": 2
    },
    {
        "id": 27,
        "name": "損害保険と税金",
        "sort": 11,
        "categoryId": 2
    },
    {
        "id": 28,
        "name": "経済指標",
        "sort": 1,
        "categoryId": 3
    },
    {
        "id": 29,
        "name": "金融市場と金融政策",
        "sort": 2,
        "categoryId": 3
    },
    {
        "id": 30,
        "name": "金融機関のセーフティネット",
        "sort": 3,
        "categoryId": 3
    },
    {
        "id": 31,
        "name": "金融商品の関連法規",
        "sort": 4,
        "categoryId": 3
    },
    {
        "id": 32,
        "name": "金利と預金",
        "sort": 5,
        "categoryId": 3
    },
    {
        "id": 33,
        "name": "債券の基本",
        "sort": 6,
        "categoryId": 3
    },
    {
        "id": 34,
        "name": "債券の利回りとリスク",
        "sort": 7,
        "categoryId": 3
    },
    {
        "id": 35,
        "name": "株式の基本",
        "sort": 8,
        "categoryId": 3
    },
    {
        "id": 36,
        "name": "株式の指標",
        "sort": 9,
        "categoryId": 3
    },
    {
        "id": 37,
        "name": "投資信託の基本と仕組み",
        "sort": 10,
        "categoryId": 3
    },
    {
        "id": 38,
        "name": "投資信託の分類",
        "sort": 11,
        "categoryId": 3
    },
    {
        "id": 40,
        "name": "外貨建て金融商品・金",
        "sort": 12,
        "categoryId": 3
    },
    {
        "id": 41,
        "name": "ポートフォリオ理論・デリバティブ",
        "sort": 13,
        "categoryId": 3
    },
    {
        "id": 42,
        "name": "金融商品と税金",
        "sort": 14,
        "categoryId": 3
    },
    {
        "id": 43,
        "name": "所得税の基本",
        "sort": 1,
        "categoryId": 4
    },
    {
        "id": 44,
        "name": "所得の10分類",
        "sort": 2,
        "categoryId": 4
    },
    {
        "id": 45,
        "name": "損益通算の基本",
        "sort": 3,
        "categoryId": 4
    },
    {
        "id": 46,
        "name": "所得控除の全体像",
        "sort": 4,
        "categoryId": 4
    },
    {
        "id": 47,
        "name": "所得控除",
        "sort": 5,
        "categoryId": 4
    },
    {
        "id": 48,
        "name": "税額控除",
        "sort": 6,
        "categoryId": 4
    },
    {
        "id": 49,
        "name": "確定申告",
        "sort": 7,
        "categoryId": 4
    },
    {
        "id": 50,
        "name": "不動産の基本",
        "sort": 1,
        "categoryId": 5
    },
    {
        "id": 51,
        "name": "宅地建物取引業法",
        "sort": 2,
        "categoryId": 5
    },
    {
        "id": 52,
        "name": "借地借家法",
        "sort": 3,
        "categoryId": 5
    },
    {
        "id": 53,
        "name": "区分所有法",
        "sort": 4,
        "categoryId": 5
    },
    {
        "id": 54,
        "name": "都市計画法",
        "sort": 5,
        "categoryId": 5
    },
    {
        "id": 55,
        "name": "建築基準法",
        "sort": 6,
        "categoryId": 5
    },
    {
        "id": 56,
        "name": "農地法",
        "sort": 7,
        "categoryId": 5
    },
    {
        "id": 57,
        "name": "不動産の税金",
        "sort": 8,
        "categoryId": 5
    },
    {
        "id": 58,
        "name": "不動産の有効活用・不動産投資",
        "sort": 9,
        "categoryId": 5
    },
    {
        "id": 59,
        "name": "相続の基礎知識",
        "sort": 1,
        "categoryId": 6
    },
    {
        "id": 60,
        "name": "遺産分割",
        "sort": 2,
        "categoryId": 6
    },
    {
        "id": 61,
        "name": "相続税の計算の流れ",
        "sort": 3,
        "categoryId": 6
    },
    {
        "id": 62,
        "name": "財産、各種控除、非課税の理解と相続税の計算",
        "sort": 4,
        "categoryId": 6
    },
    {
        "id": 63,
        "name": "相続税の申告と納付",
        "sort": 5,
        "categoryId": 6
    },
    {
        "id": 64,
        "name": "贈与と贈与税",
        "sort": 6,
        "categoryId": 6
    },
    {
        "id": 65,
        "name": "贈与税の特例",
        "sort": 7,
        "categoryId": 6
    },
    {
        "id": 66,
        "name": "相続時精算課税制度",
        "sort": 8,
        "categoryId": 6
    },
    {
        "id": 67,
        "name": "贈与税の申告と納付",
        "sort": 9,
        "categoryId": 6
    },
    {
        "id": 68,
        "name": "宅地の評価",
        "sort": 10,
        "categoryId": 6
    },
    {
        "id": 69,
        "name": "株式およびその他の財産の評価",
        "sort": 11,
        "categoryId": 6
    }
]